import Button from 'common/components/button';

const CTA_Buttons = ({ 
  onPrimaryClick,
  onSecondaryClick,
  loadingPrimary = false,
  loadingSecondary = false,
  disablePrimary = false,
  disableSecondary = false,
  fullHeight = false,
  primaryTitle,
  secondaryTitle,
 }) => {
  return (
    <div className={`flex gap-3 items-center z-30 mobile:flex-col mobile:fixed mobile:right-5 mobile:bottom-6 ${fullHeight && 'mt-auto pb-10'}`}>
      {/* Primary Button */}
      {onPrimaryClick && (
        <Button
        loading={loadingPrimary}
        disabled={disablePrimary}
        onClick={onPrimaryClick}        
        title={primaryTitle}
        className={disablePrimary ? 'hidden' : 'pr-6 mobile:shadow-md'}
      />
      )}

      {/* Secondary Button */}
      {onSecondaryClick && (
        <Button
          theme='secondary'
          loading={loadingSecondary}
          disabled={disableSecondary}
          onClick={onSecondaryClick}
          title={secondaryTitle}
          className={disableSecondary ? 'hidden' : 'pl-6 mobile:shadow-md'}
        />
      )}
    </div>
  )
}

export default CTA_Buttons
