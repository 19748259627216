import WrapInputLabel from 'common/components/wrap-input-label';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useUpdatePasswordMutation } from 'redux/api/ciosUsersApi/ciosUsersApi';
import {
  LOWERCASECASE_REGEX,
  NOT_STRICT_DIGIT_REGEX,
  UPPERCASE_REGEX,
} from 'utils/regex';
import Button from 'common/components/button';
import FormError from 'common/components/form-error';
import PasswordInput from 'common/components/password-input';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import Tracker from 'components/settings/password-form/tracker';
import { ApplyFormField, ApplyFormLayout } from 'common/layouts/apply-form';
import { PasswordRequirements } from 'common/components/password-requirements';

const PasswordForm = () => {
  // HOOKS
  const [updatePassword, { isLoading }] = useUpdatePasswordMutation();
  const {
    trackFormSubmission,
    trackFormSubmissionSuccess,
    trackFormSubmissionError,
  } = useUserTracker();

  return (
    <Formik
      initialValues={{
        currentPassword: '',
        newPassword: '',
        repeatPassword: '',
      }}
      validationSchema={Yup.object({
        currentPassword: Yup.string().required('Required'),

        newPassword: Yup.string()
          .required('Required')
          .min(8, 'Must be at least 8 characters long')
          .matches(NOT_STRICT_DIGIT_REGEX, 'Must contain a number')
          .matches(LOWERCASECASE_REGEX, 'Must contain a lowercase')
          .matches(UPPERCASE_REGEX, 'Must contain an uppercase'),
        repeatPassword: Yup.string()
          .required('Required')
          .oneOf([Yup.ref('newPassword')], 'Passwords must match'),
      })}
      onSubmit={(values, { resetForm }) => {
        trackFormSubmission({});
        updatePassword({
          newPassword: values.newPassword,
          oldPassword: values.currentPassword,
        })
          .unwrap()
          .then(() => {
            trackFormSubmissionSuccess({});
            toast.info('Your password has been update!');
            resetForm();
          })
          .catch((e) => {
            trackFormSubmissionError(
              {},
              e.data?.msg || `Couldn't update password`
            );
            toast.error('Failed to update password!');
          });
      }}
    >
      {({
        isValid,
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        errors,
        touched,
        setFieldTouched,
      }) => (
        <form
          onSubmit={(e) => e.preventDefault()}
          className='w-full flex flex-col gap-6'
        >
          {/* text */}
          <h2 className='text-lg font-medium'>Change Password</h2>

          <ApplyFormLayout padding={false}>
            <ApplyFormField>
              <WrapInputLabel isRequired>Current Password</WrapInputLabel>
              <PasswordInput
                name='newPassword'
                onChange={(e) => {
                  setFieldTouched('newPassword');
                  handleChange(e);
                }}
                onBlur={handleBlur}
                value={values.newPassword}
                placeholderText='Enter your new password'
              />
              <FormError name='newPassword' />
            </ApplyFormField>
            <ApplyFormField>
              <WrapInputLabel isRequired>New Password</WrapInputLabel>
              <PasswordInput
                name='newPassword'
                onChange={(e) => {
                  setFieldTouched('newPassword');
                  handleChange(e);
                }}
                onBlur={handleBlur}
                value={values.newPassword}
                placeholderText='Enter your new password'
              />
              <FormError name='newPassword' />
            </ApplyFormField>
            <ApplyFormField>
              <PasswordRequirements password={values.newPassword} />
            </ApplyFormField>
          </ApplyFormLayout>

          {/* inputs */}
          <div className='w-full flex flex-col gap-5'>
            

            {/* confirm password */}
            <div className='w-full flex flex-col gap-2'>
              <WrapInputLabel isRequired>Confirm Password</WrapInputLabel>
              <PasswordInput
                name='repeatPassword'
                onChange={(e) => {
                  setFieldTouched('repeatPassword');
                  handleChange(e);
                }}
                onBlur={handleBlur}
                value={values.repeatPassword}
                placeholderText='Re-enter your new password'
              />
              <FormError name='repeatPassword' />
            </div>
          </div>

          {/* button */}
          <div>
            <Button
              linkId='changePasswordBtn'
              loading={isLoading}
              onClick={handleSubmit}
              disabled={!isValid || isLoading}
              title='Change password'
              wFull={false}
            />
            <Tracker
              values={values}
              errors={errors}
              touched={touched}
              formName='changePassword'
            />
          </div>
        </form>
      )}
    </Formik>
  );
};

export default PasswordForm;
