import { Formik } from 'formik';
import { useRef } from 'react';
import { useUpdateClaimMutation } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { toast } from 'react-toastify';
import ApplyButtons from 'layouts/apply-buttons';
import { ApplyFormContainer, ApplyFormField, ApplyFormLayout } from 'common/layouts/apply-form';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Flex, Radio, RadioGroup, Text } from '@chakra-ui/react';
import WrapInputLabel from 'common/components/wrap-input-label';
import FormError from 'common/components/form-error';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import { extractErrorMsg } from 'utils/errors';
import ContactCard from 'components/shared/contact-card';
import ApplyTitle from 'components/apply-shared/apply-title';
import ApplyContent from 'components/apply-shared/apply-content';

const Type = ({ claim }) => {
  const formikRef = useRef(null);
  const [
    updateClaim,
    { data: updatedClaimData, isLoading: updateClaimLoading },
  ] = useUpdateClaimMutation();
  const navigate = useNavigate();
  const {
    trackFormSubmission,
    trackFormSubmissionSuccess,
    trackFormSubmissionError,
  } = useUserTracker();

  // DATA INITIALIZATION
  // Check what coverages are on the plan for the claim
  const hasCancelAnytime = claim?.planId?.coverage?.cancelAnytime?.payment?.stripe?.id;
  const hasLeaveAnytime = claim?.planId?.coverage?.leaveAnytime?.payment?.onetime?.stripe?.id || claim?.planId?.coverage?.leaveAnytime?.payment?.subscription?.stripe?.id;

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={{
        claimType: claim?.claimType,
      }}
      validationSchema={Yup.object().shape({
        claimType: Yup.string().required('Required'),
      })}
      onSubmit={(values) => {
        let submissionValues = {
          claimId: claim.id,
          claimType: values.claimType,
          submission: {
            ...claim?.submission,
          },
        };
        trackFormSubmission(submissionValues);
        updateClaim(submissionValues)
          .unwrap()
          .then(() => {
            navigate(`/dashboard/apply-for-claims/${claim.id}`);
            trackFormSubmissionSuccess(submissionValues);
          })
          .catch((error) => {
            const errMsg = extractErrorMsg(error, 'Update Failed');
            toast.error(errMsg);
            trackFormSubmissionError(submissionValues, errMsg);
          });
      }}
    >
      {({
        handleBlur,
        handleChange,
        setFieldValue,
        values,
        handleSubmit,
        isValid,
        setFieldTouched,
      }) => {
        return (
          <div className='flex flex-col w-full h-full'>
            {/* Header Area */}
            <ApplyTitle
              title='Claim Type'
              subtitle='Please let us know what type of claim you are looking to submit.'
              buttons={
                <ApplyButtons
                  loading={updateClaimLoading}
                  isValid={isValid && values.claimType !== 'none'}
                  moveOn={false}
                  handleSubmit={handleSubmit}
                  disableBack
                />
              }
            />

            {/* Main Area */}
            <ApplyContent>

              {/* container */}
              <ApplyFormContainer>                
                <ApplyFormLayout>                  
                  <Text className='font-medium col-span-2'>
                    Choose an option from the list below:
                  </Text>
                  <ApplyFormField>
                    <WrapInputLabel isRequired>Claim Type</WrapInputLabel>

                    <RadioGroup
                      className='flex flex-col gap-4'
                      onChange={(option) => {
                        if (option) {
                          setFieldValue('claimType', option).then(() => {
                            setFieldTouched('claimType');
                          });
                        } else {
                          // Handle case when select is cleared
                          setFieldValue('claimType', null).then(() => {
                            setFieldTouched('claimType');
                          });
                        }  
                      }}
                    >
                      {hasLeaveAnytime && (
                        <Flex className='rounded-md border border-gray-200 divide-y divide-gray-200 p-4'>
                          <Radio value='leaveAnytime' size='lg'>
                            <Text className='ml-2 text-base'>
                              Move out early and cancel the reminder of my lease at {claim?.planId?.lease?.address?.streetAddress1} in {claim?.planId?.lease?.address?.city}, {claim?.planId?.lease?.address?.state}
                            </Text>
                          </Radio>
                        </Flex>
                      )}
                      
                      {hasCancelAnytime && (
                        <Flex className='rounded-md border border-gray-200 divide-y divide-gray-200 p-4'>
                          <Radio value='cancelAnytime' size='lg'>
                            <Text className='ml-2 text-base'>
                              Cancel my upcoming lease at {claim?.planId?.lease?.address?.streetAddress1} in {claim?.planId?.lease?.address?.city}, {claim?.planId?.lease?.address?.state}
                            </Text>
                          </Radio>
                        </Flex>
                      )}                      
                    </RadioGroup>

                    <Accordion 
                      // defaultIndex={!savedPlansValue.property && [0]}
                      allowToggle className='border rounded-md mt-4'
                    >
                      <AccordionItem className='border-none rounded-b-md'>
                        <AccordionButton className='flex w-full gap-2 justify-between py-3'>
                          <Text className='font-medium text-base text-left'>
                            Don't see the option you're looking for?
                          </Text>
                          <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel className='border-t pt-3'>
                          <Text className='text-base text-gray-500 mb-3'>
                            If you don't see the option you're looking for, please reach out to us directly.
                          </Text>
                          {/* Can't find property */}
                          <ContactCard small />
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>

                    <FormError name='claimType' />
                  </ApplyFormField>
                </ApplyFormLayout>
              </ApplyFormContainer>
            </ApplyContent>
          </div>
        );
      }}
    </Formik>
  );
};

export default Type;
