import { Flex, Skeleton, Text } from '@chakra-ui/react';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  useGetSinglePlanQuery,
  useRetrieveUserDetailsQuery,
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import {
  clearPlansDraft,
  selectPlansValues,
} from 'redux/features/plans/plansSlice';
import {
  coverageTypes,
} from 'utils/enums';
import { isEmpty } from 'common/utils/isEmpty';
import PlanInfoCard from 'components/plans/plan-info-card';
import { setUserDetails } from 'redux/features/auth/authSlice';
import PaymentSummaryCard from 'components/plans/payment-summary-card';
import ApplyButtons from 'layouts/apply-buttons';
import {
  CoverageCard,
  CoverageDetail,
} from 'components/coverages/coverage-card';
import generalLimits from 'components/coverages/general-limits';
import ApplyTitle from 'components/apply-shared/apply-title';
import ApplyContent from 'components/apply-shared/apply-content';

const PlanInReview = ({
  isGettingPlan,
}) => {
  const { trackPageLoad, trackLink } = useUserTracker();
  useEffect(() => {
    trackPageLoad('PlanInReview');
  }, []);

  // SIDE EFFECTS
  useEffect(() => {
    if (isRetrieveUserDetailsSuccess && !isEmpty(userDetails)) {
      dispatch(setUserDetails(userDetails));
    }
  }, []);

  // HOOKS
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const plansValue = useSelector(selectPlansValues);
  const { data: singlePlan, isLoading: isGettingSinglePlan } =
    useGetSinglePlanQuery(plansValue?.id, {
      skip: isEmpty(plansValue?.id),
    });
  const { isSuccess: isRetrieveUserDetailsSuccess, data: userDetails } =
    useRetrieveUserDetailsQuery();

  // DATA INITIALIZATION
  const hasCancelAnytime =
    singlePlan?.coverage?.cancelAnytime?.payment?.stripe?.id;
  const hasLeaveAnytime =
    singlePlan?.coverage?.leaveAnytime?.payment?.onetime?.stripe?.id ||
    singlePlan?.coverage?.leaveAnytime?.payment?.subscription?.stripe?.id;
  const hasCoverage = hasCancelAnytime || hasLeaveAnytime;

  return (
    <div className='flex flex-col w-full h-full'>
      <ApplyTitle
        title='Review in Progress'
        subtitle='We’re reviewing your details and will update you shortly.'
        buttons={
          <ApplyButtons
            isValid={true}
            handleSubmit={() => {
              navigate('/dashboard/plans');
              dispatch(clearPlansDraft());
            }}
            onNextClick={() => {
              navigate('/dashboard/plans');
              dispatch(clearPlansDraft());
            }}
            disableBack
            nextTitle='Home'
          />
        }
      />

      {/* main content */}
      <ApplyContent>
        {isGettingSinglePlan || isGettingPlan ? (
          <Flex className='flex-col gap-12'>
            <Skeleton className='rounded-md h-[10dvh] w-full' />
            <Flex className='w-full gap-12'>
              <Skeleton className='rounded-md h-[40dvh] w-full' />
              <Skeleton className='rounded-md h-[40dvh] w-full' />
            </Flex>
            <Skeleton className='rounded-md h-[40dvh] w-full' />
            <Skeleton className='rounded-md h-[40dvh] w-full' />
          </Flex>
        ) : isEmpty(singlePlan) ? (
          <Flex className='border-gray-200 rounded-md w-full h-[60dvh] flex items-center justify-center'>
            <Text className='text-lg font-medium'>
              No plan found. Please go back and select a plan.
            </Text>
          </Flex>
        ) : (
          <>
            {/* plan card and info */}
            <Flex className='flex-col gap-6'>
              <h2 className='text-lg font-medium'>Your Plan Details</h2>

              {/* plan info card */}
              <Flex className='flex-col gap-4 w-full'>
                <PlanInfoCard plan={singlePlan} member={userDetails} />
              </Flex>

              <PaymentSummaryCard plan={singlePlan} complete={true} />
            </Flex>

            {/* plan coverages section */}
            {hasCoverage ? (
              <Flex className='flex-col gap-6'>
                {/* coverages heading */}
                <Flex className='flex-col gap-1 w-full items-left'>
                  <h2 className='text-lg font-medium'>
                    Plan Coverages
                  </h2>
                  <Text className='text-base text-gray-500'>
                    Your plan includes the following coverages, limits,
                    deductibles, and exclusions.
                  </Text>
                </Flex>

                {/* coverages */}
                {hasCancelAnytime && (
                  <CoverageCard
                    coverageType={coverageTypes.CANCEL_ANYTIME}
                    startDate={singlePlan.coverage.cancelAnytime.startDate}
                    endDate={singlePlan.coverage.cancelAnytime.endDate}
                  >
                    {generalLimits}
                  </CoverageCard>
                )}
                {hasLeaveAnytime && (
                  <CoverageCard
                    coverageType={coverageTypes.LEAVE_ANYTIME}
                    startDate={singlePlan.coverage.leaveAnytime.startDate}
                    endDate={singlePlan.coverage.leaveAnytime.endDate}
                  >
                    {generalLimits}

                    {/* Elimination Period / Minimum Required Stay */}
                    <CoverageDetail
                      title='Required Stay'
                      limit={['one month', '1/12 of the lease term']}
                    >
                      The required stay is the minimum amount of time you must
                      wait once your coverage period begins before you are
                      eligible to leave early and receive benefits. For example,
                      if your required stay is one month, you must wait one
                      month after your coverage period begins before you are
                      eligible to leave early and receive benefits.
                    </CoverageDetail>
                  </CoverageCard>
                )}
              </Flex>
            ) : null}
          </>
        )}
      </ApplyContent>
    </div>
  );
};

export default PlanInReview;
