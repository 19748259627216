import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Flex, Icon, Tag, Text } from '@chakra-ui/react';
import FileUploadInput from 'common/components/file-upload-input';
import WrapInputLabel from 'common/components/wrap-input-label';
import ApplyButtons from 'layouts/apply-buttons';
import { useUploadRequestUrlMutation } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { useSelector } from 'react-redux';
import { selectPlansValues } from 'redux/features/plans/plansSlice';
import {
  useGetActivatePlanQuery,
  useActivatePlanMutation,
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import { toast } from 'react-toastify';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import { uploadFileToS3 } from 'utils/uploadS3';
import { extractErrorMsg } from 'utils/errors';
import { HiCheckBadge } from 'react-icons/hi2';
import ApplyTitle from 'components/apply-shared/apply-title';
import ApplyContent from 'components/apply-shared/apply-content';
import { ApplyFormField } from 'common/layouts/apply-form';

const PlanStepActivatePlan = ({
  stepNumber,
  onBackClick,
  onNextClick,
  ...args
}) => {
  const [leaseDoc, setLeaseDoc] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    url: '',
  });
  const {
    trackLink,
    trackPageLoad,
    trackCustomEvent,
    trackFormSubmission,
    trackFormSubmissionSuccess,
    trackFormSubmissionError,
  } = useUserTracker();
  useEffect(() => {
    trackPageLoad('activatePlanLeaseAndVerification');
  }, []);
  const [activatePlan, { isLoading: isActivateLoading }] =
    useActivatePlanMutation();
  const [idDoc, setIDDoc] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    url: '',
  });
  const [uploadRequestUrl] = useUploadRequestUrlMutation();
  const plansValue = useSelector(selectPlansValues);
  const [searchParams, setSearchParams] = useSearchParams();
  const searchPlanId = searchParams.get('planId');
  const { data: singlePlan, isLoading: isGettingSinglePlan } =
    useGetActivatePlanQuery(plansValue?.id || searchPlanId, {});

  let planId = plansValue?.id || singlePlan?.id || searchPlanId;

  // Check for previous lease doc
  useEffect(() => {
    if (singlePlan?.leaseDocUrl) {
      setLeaseDoc((old) => ({
        ...old,
        isLoading: false,
        isSuccess: true,
        isError: false,
        url: singlePlan?.leaseDocUrl,
      }));
    }

    if (singlePlan?.idUrl) {
      setIDDoc((old) => ({
        ...old,
        isLoading: false,
        isSuccess: true,
        isError: false,
        url: singlePlan?.idUrl,
      }));
    }
  }, [singlePlan?.leaseDocUrl, singlePlan?.idUrl]);

  return (
    <div className='flex flex-col w-full h-full'>
      <ApplyTitle
        title='Activate Plan'
        subtitle='Verify your ID and complete your plan activation.'
        buttons={
          <ApplyButtons
            moveOn={true}
            nextTitle='Activate'
            isValid={
              (singlePlan?.idUrl || idDoc?.url) &&
              (singlePlan?.leaseDocUrl || leaseDoc?.url)
            }
            onNextClick={() => {
              const submitValues = {
                planId,
                leaseDocUrl: leaseDoc.url,
                idUrl: idDoc.url,
              };
              trackFormSubmission(submitValues);
              activatePlan(submitValues)
                .unwrap()
                .then((d) => {
                  trackFormSubmissionSuccess(submitValues);

                  if (leaseDoc.isSuccess && idDoc.isSuccess) {
                    onNextClick();
                  }
                })
                .catch((e) => {
                  const errMsg = extractErrorMsg(e, 'Update Failed');
                  toast.error(errMsg);
                  trackFormSubmissionError(submitValues, errMsg);
                });
            }}
            disableBack
          />
        }
      />

      {/* main content */}
      <ApplyContent>
        <Flex className='flex-col w-full gap-6'>
          {leaseDoc?.url && idDoc?.url ? (
            <Flex className='flex-col gap-1 w-full items-left'>
              <h2 className='text-lg font-medium'>
                You're Ready to Activate Your Plan
              </h2>
              <Text className='text-base text-gray-500'>
                Since you've already uploaded your lease agreement and ID,
                you're all set to activate your plan.
              </Text>
            </Flex>
          ) : (
            <Flex className='flex-col gap-1 w-full items-left'>
              <h2 className='text-lg font-medium'>
                Complete Your Verification
              </h2>
              <Text className='text-base text-gray-500'>
                Please confirm a few details to activate your plan. It'll only
                take you five minutes.
              </Text>
            </Flex>
          )}
        </Flex>

        {/* Lease Document is conditional on not being uploaded previously */}
        <Flex className='flex-col w-full gap-6'>
          {/* title */}
          <Flex className='flex-col gap-1 w-full items-left'>
            <h2 className='text-lg font-medium'>
              Verify Your Lease Agreement
              {singlePlan?.leaseDocUrl?.url && (
                <Tag
                  className='ml-4 pl-2 pr-3 gap-2 rounded-full text-sm'
                  colorScheme='green'
                >
                  <Icon as={HiCheckBadge} />
                  Uploaded
                </Tag>
              )}
            </h2>
            <Text className='text-base text-gray-500'>
              To complete your verification, simply upload a PDF copy of your
              full lease agreement. Make sure all pages are included in the
              file.
            </Text>
          </Flex>

          {!singlePlan?.leaseDocUrl?.url && (
            <ApplyFormField>
              <WrapInputLabel isRequired>
                Upload Your Current Lease Agreement
              </WrapInputLabel>

              {/* file upload input */}
              <FileUploadInput
                disableUpload={leaseDoc.isLoading}
                isUploadError={leaseDoc.isError}
                isUploadLoading={leaseDoc.isLoading}
                isUploadSuccess={leaseDoc.isSuccess}
                handlePDFClear={() => {
                  setLeaseDoc((old) => ({
                    ...old,
                    isLoading: false,
                    isSuccess: false,
                    isError: false,
                    url: '',
                  }));
                }}
                handlePDFUpload={async (file) => {
                  let uploadUrlReq;
                  let fileTrack = { fileName: file.name, size: file.size };
                  setLeaseDoc((old) => ({
                    ...old,
                    isLoading: true,
                    fileName: file.name,
                  }));

                  // Get the signing url for uploading to s3
                  try {
                    trackCustomEvent('fileUploadSelection', {
                      name: 'leaseDocUrl',
                      file: fileTrack,
                    });
                    trackCustomEvent('s3UrlUploadRequest', {
                      name: 'leaseDocUrl',
                      file: fileTrack,
                    });
                    uploadUrlReq = await uploadRequestUrl({
                      fileName: file.name,
                      contentType: file.type,
                    }).unwrap();
                  } catch (e) {
                    setLeaseDoc((old) => ({
                      ...old,
                      isLoading: false,
                      isError: true,
                      fileName: file.name,
                    }));
                    const errMsg = extractErrorMsg(
                      e,
                      'failed to upload lease document'
                    );

                    toast.error(errMsg);
                    trackCustomEvent('s3UrlUploadRequestError', {
                      name: 'leaseDocUrl',
                      file: fileTrack,
                      error: errMsg,
                    });
                    return;
                  }

                  if (
                    !uploadUrlReq?.data?.uploadUrl ||
                    !uploadUrlReq?.data?.viewUrl
                  ) {
                    setLeaseDoc((old) => ({
                      ...old,
                      isLoading: false,
                      isError: true,
                      fileName: file.name,
                    }));
                    toast.error('failed to upload lease document');
                    trackCustomEvent('s3UrlUploadRequestError', {
                      name: 'leaseDocUrl',
                      file: fileTrack,
                      error: 'Improper s3 url return value',
                    });
                  }

                  // Upload the file to s3
                  try {
                    trackCustomEvent('s3FileUploadRequest', {
                      name: 'leaseDocUrl',
                      file: fileTrack,
                      s3Url: uploadUrlReq?.data,
                    });
                    await uploadFileToS3({
                      s3Url: uploadUrlReq?.data?.uploadUrl,
                      file,
                    });

                    trackCustomEvent('s3FileUploadSuccess', {
                      name: 'leaseDocUrl',
                      file: fileTrack,
                      s3Url: uploadUrlReq?.data,
                    });

                    // Have to set the uploaded file url on the lease document
                    setLeaseDoc((old) => ({
                      ...old,
                      url: uploadUrlReq?.data?.viewUrl,
                      fileName: file.name,
                      isLoading: false,
                      isError: false,
                      isSuccess: true,
                    }));

                    toast.info(
                      uploadUrlReq?.status ||
                        'lease has been uploaded successfully'
                    );
                  } catch (e) {
                    setLeaseDoc((old) => ({
                      ...old,
                      fileName: file.name,
                      isLoading: false,
                      isError: true,
                      isSuccess: false,
                    }));
                    const errMsg = extractErrorMsg(
                      e,
                      'failed to upload lease document'
                    );
                    toast.error(errMsg);
                    trackCustomEvent('s3FileUploadError', {
                      name: 'leaseDocUrl',
                      file: fileTrack,
                      s3Url: uploadUrlReq?.data,
                      error: errMsg,
                    });
                    return;
                  }
                }}
              />
            </ApplyFormField>
          )}
        </Flex>

        {/* Identity Verification */}
        <Flex className='flex-col w-full gap-6'>
          {/* title */}
          <Flex className='flex-col gap-1 w-full items-left'>
            <h2 className='text-lg font-medium'>
              Verify Your Identity
              {singlePlan?.idUrl && (
                <Tag
                  className='ml-4 pl-2 pr-3 gap-2 rounded-full text-sm'
                  colorScheme='green'
                >
                  <Icon as={HiCheckBadge} />
                  Uploaded
                </Tag>
              )}
            </h2>
            <Text className='text-base text-gray-500'>
              For identify verification, please upload a government-issued ID,
              such as a driver’s license, passport, or citizenship card. Ensure
              the ID is clear and all details are visible.
            </Text>
          </Flex>

          {!singlePlan?.idUrl ? (
            <ApplyFormField>
              <WrapInputLabel isRequired>Upload Your ID</WrapInputLabel>

              {/* file upload input */}
                <FileUploadInput
                  disableUpload={idDoc.isLoading}
                  isUploadError={idDoc.isError}
                  isUploadLoading={idDoc.isLoading}
                  isUploadSuccess={idDoc.isSuccess}
                  handlePDFClear={() => {
                    setIDDoc((old) => ({
                      ...old,
                      isLoading: false,
                      isSuccess: false,
                      isError: false,
                      url: '',
                    }));
                  }}
                  handlePDFUpload={async (file) => {
                    let uploadUrlReq;
                    setIDDoc((old) => ({ ...old, isLoading: true }));
                    let fileTrack = { fileName: file.name, size: file.size };

                    // Get the signing url for uploading to s3
                    try {
                      trackCustomEvent('fileUploadSelection', {
                        name: 'idUrl',
                        file: fileTrack,
                      });
                      trackCustomEvent('s3UrlUploadRequest', {
                        name: 'idUrl',
                        file: fileTrack,
                      });
                      uploadUrlReq = await uploadRequestUrl({
                        fileName: file.name,
                        contentType: file.type,
                      }).unwrap();
                    } catch (e) {
                      const errMsg =
                        e?.msg ||
                        e?.data?.msg ||
                        e?.message ||
                        'failed to upload ID document';
                      setIDDoc((old) => ({
                        ...old,
                        isLoading: false,
                        fileName: file.name,
                        isError: true,
                      }));
                      toast.error(errMsg);
                      trackCustomEvent('s3UrlUploadRequestError', {
                        name: 'idUrl',
                        file: fileTrack,
                        error: errMsg,
                      });
                      return;
                    }

                    if (
                      !uploadUrlReq?.data?.uploadUrl ||
                      !uploadUrlReq?.data?.viewUrl
                    ) {
                      setIDDoc((old) => ({
                        ...old,
                        isLoading: false,
                        fileName: file.name,
                        isError: true,
                      }));
                      toast.error('failed to upload ID document');
                    }

                    // Upload the file to s3

                    try {
                      trackCustomEvent('s3FileUploadRequest', {
                        name: 'idUrl',
                        file: fileTrack,
                        s3Url: uploadUrlReq?.data,
                      });
                      await uploadFileToS3({
                        s3Url: uploadUrlReq?.data?.uploadUrl,
                        file,
                      });
                      trackCustomEvent('s3FileUploadSuccess', {
                        name: 'idUrl',
                        file: fileTrack,
                        s3Url: uploadUrlReq?.data,
                      });

                      // Have to set the uploaded file url on the ID document
                      setIDDoc((old) => ({
                        ...old,
                        url: uploadUrlReq?.data?.viewUrl,
                        fileName: file.name,
                        isLoading: false,
                        isError: false,
                        isSuccess: true,
                      }));

                      toast.info(
                        uploadUrlReq?.status ||
                          'ID has been uploaded successfully'
                      );
                    } catch (e) {
                      setIDDoc((old) => ({
                        ...old,
                        fileName: file.name,
                        isLoading: false,
                        isError: true,
                        isSuccess: false,
                      }));
                      const errMsg = extractErrorMsg(
                        e,
                        'failed to upload ID document'
                      );
                      toast.error(errMsg);
                      trackCustomEvent('s3FileUploadError', {
                        name: 'idUrl',
                        file: fileTrack,
                        s3Url: uploadUrlReq?.data,
                        error: errMsg,
                      });
                      return;
                    }
                  }}
                />
            </ApplyFormField>
          ) : null}
        </Flex>
      </ApplyContent>
    </div>
  );
};

export default PlanStepActivatePlan;
