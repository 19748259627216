import { useNavigate } from 'react-router-dom';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import StatusLabel from 'common/components/status-label';
import { claimTypeEnum, claimStatusEnum } from 'common/utils/enums';
import { InfoField } from 'common/components/info-card';

const ClaimsTable = ({ claims, statuses }) => {
  const navigate = useNavigate();

  // Data initialization
  const layout = 'grid-cols-3';

  return (
    <table className='flex flex-col w-full'>
      {/* body */}
      <tbody className='w-full rounded-lg overflow-hidden border border-gray-200 divide-y'>
        {claims.map((claim, index) => (
          <tr
            key={index}
            onClick={() =>
              claim.status === claimStatusEnum.DRAFT
                ? navigate(`/dashboard/claims/submit/${claim.id}`)
                : claim.status !== claimStatusEnum.CANCELLED
                  ? navigate(`/dashboard/claims/${claim.id}`)
                  : null
            }
            className={`grid gap-x-4 w-full hover:bg-gray-50 px-3 py-2 cursor-pointer mobile:flex mobile:flex-col mobile:gap-0 ${layout}`}
          >
            {/* Most important info - what type of claim is it, and what apartment is it for? */}
            <td className='col-span-2 flex flex-col gap-1 w-full p-3 text-base font-medium items-start'>
              <InfoField
                title={
                  claim?.claimType === claimTypeEnum.cancelAnytime
                    ? 'Cancel my upcoming lease at'
                    : 'Move out early and cancel the reminder of my lease at'
                }
              >
                {claim?.planId?.lease?.address?.streetAddress1}{' '}
                {claim?.planId?.lease?.address?.streetAddress2},{' '}
                {claim?.planId?.lease?.address?.city},{' '}
                {claim?.planId?.lease?.address?.state},{' '}
                {claim?.planId?.lease?.address?.postalCode}
              </InfoField>
            </td>

            {/* Claim status */}
            <td className='flex flex-col gap-1 w-full p-3 text-base font-medium items-end mobile:items-start'>
              <StatusLabel status={claim.status} size='sm' />
            </td>

            {/* Claim ID */}
            <td className='flex flex-col gap-1 w-full p-3 text-base font-medium items-start'>
              <InfoField title='Claim Number'>
                <span className='uppercase tracking-wider'>{claim?.id}</span>
              </InfoField>
            </td>

            {/* Plan Number */}
            <td className='flex flex-col gap-1 w-full p-3 text-base font-medium items-start'>
              <InfoField title='Plan Number'>
                <span className='uppercase tracking-wider'>
                  {claim?.planId.id}
                </span>
              </InfoField>
            </td>

            {/* Last Updated */}
            <td className='flex flex-col gap-1 w-full p-3 text-base font-medium items-end mobile:items-start'>
              <InfoField title='Last Updated'>
                <span>
                  {formatDateToHumanDate(
                    claim?.statusChanges?.at(-1)?.updatedAt
                  )}
                </span>
              </InfoField>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ClaimsTable;
