import { Flex, Text } from '@chakra-ui/react';
import LogoImage from 'common/components/logo-image';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import { InfoField } from 'common/components/info-card';
import CopyLink from 'common/components/copy-link';

const PlanInfoCard = ({
  plan,
  member,
  selectedCancelAnytime = false,
  selectedLeaveAnytime = false,
}) => {
  // DATA INITIALIZATION
  const address = plan?.lease?.address;
  const name = member?.name || `${member?.firstName} ${member?.lastName}`;
  const property = plan?.property?.name;

  // Figure out what coverages the plan has
  const hasCancelAnytime =
    plan?.coverage?.cancelAnytime?.payment?.stripe?.id ||
    selectedCancelAnytime;
  const hasLeaveAnytime =
    plan?.coverage?.leaveAnytime?.payment?.onetime?.stripe?.id ||
    plan?.coverage?.leaveAnytime?.payment?.subscription?.stripe?.id || selectedLeaveAnytime;

  const hasCoverage = hasCancelAnytime || hasLeaveAnytime;

  const cancelAnytime = hasCancelAnytime ? plan?.coverage?.cancelAnytime : null;
  const leaveAnytime = hasLeaveAnytime ? plan?.coverage?.leaveAnytime : null;

  const cancelAnytimeDetails = hasCancelAnytime
    ? {
        title: 'Cancel Anytime',
        coveragePeriod: `${formatDateToHumanDate(
          cancelAnytime?.startDate
        )} - ${formatDateToHumanDate(cancelAnytime?.endDate)}`,
        limit: '6 months or $30,000',
      }
    : null;

  const leaveAnytimeDetails = hasLeaveAnytime
    ? {
        title: 'Leave Anytime',
        coveragePeriod: `${formatDateToHumanDate(
          leaveAnytime?.startDate
        )} - ${formatDateToHumanDate(leaveAnytime?.endDate)}`,
        limit: '6 months or $30,000',
      }
    : null;

  const coverages = [cancelAnytimeDetails, leaveAnytimeDetails].filter(Boolean);

  return (
    <Flex className='p-8 bg-gray-100 border border-gray-200 rounded-md flex-col gap-4 w-full'>
      {/* logo */}
      <Flex className='w-full gap-6 flex-col mb-5 sm:flex-row sm:justify-between sm:items-center'>
        <LogoImage dark={true} size='xs' />
        <Flex className='text-gray-500 uppercase tracking-widest text-sm font-semibold gap-x-4 gap-y-2 flex-col sm:flex-row'>
          <CopyLink
            text={plan?.id || ''}
            title={`plan number ${plan?.id}`}
          />
          {plan?.status}
        </Flex>
      </Flex>

      {/* street address and name */}
      <Flex className='flex-col gap-6 w-full'>
        <Flex className='flex-col gap-2'>
          <Text className='capitalize font-medium tracking-wide'>
            {address?.streetAddress1}
            {address?.streetAddress2 && `, ${address?.streetAddress2}`},{' '}
            {address?.city}
            {address?.state && `, ${address?.state}`}, {address?.country}
            {address?.postalCode && `, ${address?.postalCode}`}
          </Text>
          {property && (
            <Text className='text-gray-500 text-sm font-medium tracking-wide'>
              {property}
            </Text>
          )}
        </Flex>
        <Text className='font-medium text-base'>{name}</Text>
      </Flex>

      {/* Coverages */}
      {hasCoverage && (
        <Flex className='flex-col gap-6 w-full sm:flex-row pt-6 border-t border-gray-200'>
            {coverages?.map((coverage, index) => (
            <Flex key={index} className='flex-col gap-4 w-full'>
              <Text className='capitalize text-sm font-medium'>{coverage.title}</Text>
              <Flex className='gap-4 flex-col sm:flex-row'>
                <InfoField title='Coverage Period'>
                  {coverage.coveragePeriod}
                </InfoField>                
                {/* Not using coverage limit right now */}
                {/* <Flex key={index} className='flex-col w-full'>
                  <Flex className='uppercase tracking-wider text-sm font-bold'>                  
                    <span>Coverage Limit</span>
                  </Flex>
                  <Text className='text-lg uppercase tracking-wider'>
                    {coverage.limit}
                  </Text>
                </Flex> */}
              </Flex>
            </Flex>
            ))}
        </Flex>
      )}
    </Flex>
  );
};

export default PlanInfoCard;
