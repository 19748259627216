import { Flex, Text } from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import EmptyData from 'components/shared/empty-data';
import LoadingSpinner from 'components/shared/loading-spinner';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import { isEmpty } from 'common/utils/isEmpty';
import DownloadPdfButton from 'components/plans/download-button';
import PlanInfoCard from 'components/plans/plan-info-card';
import { useEffect } from 'react';
import {
  useGetSinglePlanQuery,
  useRetrieveUserDetailsQuery,
  useCreateClaimMutation,
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import { useDispatch } from 'react-redux';
import { setUserDetails } from 'redux/features/auth/authSlice';
import PaymentSummaryCard from 'components/plans/payment-summary-card';
import { coverageTypes } from 'utils/enums';
import ContactCard from 'components/shared/contact-card';
import {
  CoverageCard,
  CoverageDetail,
} from 'components/coverages/coverage-card';
import generalLimits from 'components/coverages/general-limits';
import CTA_Buttons from 'common/components/cta-buttons';

const PlanDetails = () => {
  // HOOKS
  const { planId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: singlePlan, isLoading: getSinglePlanLoading } =
    useGetSinglePlanQuery(planId);
  const { isSuccess: isRetrieveUserDetailsSuccess, data: userDetails } =
    useRetrieveUserDetailsQuery();
  const [createClaim, { data: claimData, isLoading: createClaimLoading }] =
    useCreateClaimMutation();

  // SIDE EFFECTS
  useEffect(() => {
    if (isRetrieveUserDetailsSuccess && !isEmpty(userDetails)) {
      dispatch(setUserDetails(userDetails));
    }
  }, []);

  // DATA INITIALIZATION
  const hasCancelAnytime =
    singlePlan?.coverage?.cancelAnytime?.payment?.stripe?.id;
  const hasLeaveAnytime =
    singlePlan?.coverage?.leaveAnytime?.payment?.onetime?.stripe?.id ||
    singlePlan?.coverage?.leaveAnytime?.payment?.subscription?.stripe?.id;
  const hasCoverage = hasCancelAnytime || hasLeaveAnytime;

  const ctaButtons = (
    <CTA_Buttons
      onPrimaryClick={() => {
        createClaim({
          value: { planID: planId },
        })
          .unwrap()
          .then((x) => {
            navigate(`/dashboard/claims/submit/${x?.data?.id}`);
          });
      }}
      onSecondaryClick={() => window.open('mailto:members@releaserent.com')}
      primaryTitle='Submit a Claim'
      secondaryTitle='Chat with an Expert'
    />
  );

  return (
    <DashboardWrapperLayout title={`Plan Details`} buttons={ctaButtons}>
      <Flex className='w-full flex-col gap-12 bg-white'>
        {/* plan card and payment summary */}
        {getSinglePlanLoading ? (
          <LoadingSpinner />
        ) : isEmpty(singlePlan) ? (
          <EmptyData />
        ) : (
          <>
            {/* plan card and info */}
            <Flex className='flex-col gap-4'>
              {/* plan heading and status */}
              <Flex className='gap-4 items-center justify-between'>
                <h2 className='text-lg font-medium'>Your Plan Details</h2>
                {singlePlan?.id && (
                  <DownloadPdfButton planId={singlePlan?.id} wFull={false} />
                )}
              </Flex>

              <PlanInfoCard plan={singlePlan} member={userDetails} />
            </Flex>

            <PaymentSummaryCard
              plan={singlePlan}
              complete={true}
              card={false}
            />

            {/* plan coverages section */}
            {hasCoverage && (
              <Flex className='flex-col gap-6'>
                {/* coverages heading */}
                <Flex className='flex-col gap-1 w-full items-left'>
                  <h2 className='text-lg font-medium'>Plan Coverages</h2>
                  <Text className='text-base text-gray-500'>
                    Your plan includes the following coverages, limits,
                    deductibles, and exclusions.
                  </Text>
                </Flex>

                {/* coverages */}
                {hasCancelAnytime && (
                  <CoverageCard
                    coverageType={coverageTypes.CANCEL_ANYTIME}
                    startDate={singlePlan.coverage.cancelAnytime.startDate}
                    endDate={singlePlan.coverage.cancelAnytime.endDate}
                  >
                    {generalLimits}
                  </CoverageCard>
                )}
                {hasLeaveAnytime && (
                  <CoverageCard
                    coverageType={coverageTypes.LEAVE_ANYTIME}
                    startDate={singlePlan.coverage.leaveAnytime.startDate}
                    endDate={singlePlan.coverage.leaveAnytime.endDate}
                  >
                    {generalLimits}

                    {/* Elimination Period / Minimum Required Stay */}
                    <CoverageDetail
                      title='Required Stay'
                      limit={['one month', '1/12 of the lease term']}
                    >
                      The required stay is the minimum amount of time you must
                      wait once your coverage period begins before you are
                      eligible to leave early and receive benefits. For example,
                      if your required stay is one month, you must wait one
                      month after your coverage period begins before you are
                      eligible to leave early and receive benefits.
                    </CoverageDetail>
                  </CoverageCard>
                )}
              </Flex>
            )}
          </>
        )}

        {/* Contact Us Card */}
        <ContactCard title='Contact Us'>
          Questions about your plan or with claims? Contact us by email, phone,
          or text and we can help you answer any questions you may have about
          purchasing a plan, making claims, and more.
        </ContactCard>
      </Flex>
    </DashboardWrapperLayout>
  );
};

export default PlanDetails;
