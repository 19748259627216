import Button from 'common/components/button';
import { settingsTab } from 'utils/enums';

const Tabs = ({ currentTab, setCurrentTab }) => {
  return (
    <div className='flex gap-3 items-center z-30 mobile:fixed mobile:right-5 mobile:bottom-6'>
      <Button
        onClick={() => setCurrentTab(settingsTab.ACCOUNT)}
        title='My Account'
        theme={currentTab === settingsTab.ACCOUNT ? 'primary' : 'secondary'}
        wFull={false}
        className='mobile:shadow-md'
      />
      <Button
        onClick={() => setCurrentTab(settingsTab.VERIFICATION)}
        title='ID Verification'
        theme={currentTab === settingsTab.VERIFICATION ? 'primary' : 'secondary'}
        wFull={false}
        className='mobile:shadow-md'
      />
    </div>
  );
};

export default Tabs;
