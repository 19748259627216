import { Formik } from 'formik';
import { useRef } from 'react';
import {
  useUpdateClaimMutation,
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import { doesClaimRequirePayment } from 'common/utils/misc';
import ApplyButtons from 'layouts/apply-buttons';
import { ApplyFormContainer, ApplyFormField, ApplyFormLayout } from 'common/layouts/apply-form';
import { InfoField } from 'common/components/info-card';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import { Flex, Text } from '@chakra-ui/react';
import WrapInputLabel from 'common/components/wrap-input-label';
import Input from 'common/components/input';
import FormError from 'common/components/form-error';
import ContactCard from 'components/shared/contact-card';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { toDatePickerFormat } from 'utils/date';
import { toast } from 'react-toastify';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import { extractErrorMsg } from 'utils/errors';
import ApplyTitle from 'components/apply-shared/apply-title';
import ApplyContent from 'components/apply-shared/apply-content';

const ResolvedClaim = ({ claim, claimId }) => {
  const formikRef = useRef(null);
  const navigate = useNavigate();
  const [updateClaim, { isLoading: updateClaimLoading }] = useUpdateClaimMutation();
  const {
    trackFormSubmission,
    trackFormSubmissionSuccess,
    trackFormSubmissionError,
  } = useUserTracker();

  const handleReturn = () => {
    navigate(`/dashboard/claims/${claimId}`);
  };

  const isApproved = claim?.review?.dates?.moveOutStart || claim?.review?.dates?.moveOutEnd;

  const requiresPayment = doesClaimRequirePayment(claim);
  const totalPayment = claim?.memberPayment?.deductible + claim?.memberPayment?.nonEligibleCharges;

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={{}}
      onSubmit={(values) => {
        let submissionValues = {
          claimId: claimId,          
          submission: {
            ...claim?.submission,        
            confirmedDate: values?.submission?.confirmedDate,
          },
          // submission: {
          //   moveReason: claim?.submission?.moveReason,
          //   moveOutStart: claim?.submission?.moveOutStart,
          //   moveOutEnd: claim?.submission?.moveOutEnd,
          //   paymentPreference: claim?.submission?.paymentPreference,
          //   confirmedDate: values?.submission?.confirmedDate,
          // },
        };
        trackFormSubmission(submissionValues);
        updateClaim(submissionValues)
          .unwrap()
          .then(() => {
            navigate(`/dashboard/claims/${claimId}/review`);
            trackFormSubmissionSuccess(submissionValues);
          })
          .catch((error) => {
            const errMsg = extractErrorMsg(error, 'Update Failed');
            toast.error(errMsg);
            trackFormSubmissionError(submissionValues, errMsg);
          });
      }}
    >
      {({
        handleBlur,
        handleChange,
        setFieldValue,
        values,
        handleSubmit,
        isValid,
        setFieldTouched,
        dirty,
      }) => {
        return (
          <div className='flex flex-col w-full h-full'>
            <ApplyTitle
              title='Review Claim Resolution'
              subtitle='Please review the claim resolution and finalize the claim.'
              buttons={
                <ApplyButtons
                loading={updateClaimLoading}
                isValid={isApproved ? isValid && dirty : true}
                moveOn={false}
                handleSubmit={isApproved ? handleSubmit : handleReturn}
                nextTitle={isApproved ? 'Next' : 'Return to Claim'}
                disableBack
              /> 
              }
            />

            {/* Main Area */}
            <ApplyContent>
              {/* container */}
              <ApplyFormContainer>                
                <ApplyFormLayout>
                  <Flex className='flex-col gap-2 mb-6 col-span-2'>
                    <Text className='font-medium text-lg col-span-2'>
                      Please review the resolution
                    </Text>
                    <Text className='text-base text-gray-500 col-span-2'>
                      {isApproved ? ' You’ve been approved for an early move-out within the timeframe below. Please select your preferred move-out date.' : 'Your claim has been reviewed and a decision has been made. Please review the resolution below.'}
                    </Text>
                    {requiresPayment && isApproved ? (
                      <Text className='text-base text-gray-500 col-span-2'>
                        To finalize your claim, you must pay the deductible and any non-eligible charges, such as leasing concessions and fees not covered by your plan. Your move-out cannot proceed until payment is completed.
                      </Text>
                      ) : null}
                    <Text className='text-base text-gray-500 col-span-2'>
                      If you have any questions or concerns, please reach out to our support team.
                    </Text>
                  </Flex>
                </ApplyFormLayout>

                <ApplyFormLayout className='py-6'>
                  <h2 className='font-medium text-lg col-span-2'>
                    Resolution Details
                  </h2>

                  {isApproved && (
                    <>
                      <InfoField title='Approved Earliest Move Out Date'>
                        {formatDateToHumanDate(claim?.review?.dates?.moveOutStart)}
                      </InfoField>
                      <InfoField title='Approved Latest Move Out Date'>
                        {formatDateToHumanDate(claim?.review?.dates?.moveOutEnd)}
                      </InfoField>
                    </>
                  )}

                  {isApproved && requiresPayment ? (
                    <>
                      <InfoField title='Deductible'>
                        ${claim?.memberPayment?.deductible} {claim?.planId?.lease?.currency}
                      </InfoField>
                      <InfoField title='Non-Eligible Charges'>
                        ${claim?.memberPayment?.nonEligibleCharges} {claim?.planId?.lease?.currency}
                      </InfoField>
                      <InfoField title='Total Payment'>
                        ${totalPayment} {claim?.planId?.lease?.currency}
                      </InfoField>
                    </>
                  ) : null}

                  <InfoField title='Reason' className='col-span-2'>
                    {claim?.review?.reason}
                  </InfoField>
                </ApplyFormLayout>
                
                {isApproved ? (
                  <ApplyFormLayout className='pt-6'>
                    <Flex className='flex-col gap-2 col-span-2'>
                      <h2 className='text-lg font-medium'>
                        Preferred Move-Out Date
                      </h2>
                      <Text className='text-base text-gray-500'>
                        Please select your preferred move-out date from the approved timeframe.
                      </Text>
                    </Flex>
                    
                    <ApplyFormField>
                      <WrapInputLabel isRequired>Preferred Move-Out Date</WrapInputLabel>
                      <Input
                        type='date'
                        name='submission.confirmedDate'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.submission?.confirmedDate}
                        placeholderText='Select date'
                        min={toDatePickerFormat(claim?.review?.dates?.moveOutStart)}
                        max={toDatePickerFormat(claim?.review?.dates?.moveOutEnd)}
                      />
                      <FormError name='submission.confirmedDate' />
                    </ApplyFormField>
                  </ApplyFormLayout>
                ) : (
                  <ApplyFormLayout className='pt-6'>
                    <Flex className='flex-col gap-2 col-span-2'>
                      <h2 className='text-lg font-medium'>
                        Concerns or Questions
                      </h2>
                      <Text className='text-base text-gray-500'>
                        If you have any concerns or questions about the resolution, please reach out to our support team.
                      </Text>
                    </Flex>
                    <ContactCard small className='col-span-2'/>
                  </ApplyFormLayout>
                )}
              </ApplyFormContainer>
            </ApplyContent>
          </div>
        );
      }}
    </Formik>
  );
};

export default ResolvedClaim
