import { Link } from 'common/utils/user-tracker/react-router-dom';
import Button from 'common/components/button';

// This component is specifically for the dashboard messages that we're implementing for activations
// They contain an icon, title, description, and a button that links to an action

const DashboardMessage = ({ Icon, path='', title='', description=''}) => {
  return (
    <Link linkId={title} className='w-full' to={path}>
      <div className='grid grid-cols-[auto,1fr] grid-rows-[auto] gap-x-4 gap-y-2 p-5 border border-gray-200 rounded-lg'>
        <div className='flex items-center h-6 row-span-3'>
          <Icon className='text-2xl' />
        </div>
        <span className='text-lg font-medium'>
          {title}
        </span>
        <p className='text-base text-gray-500'>
          {description}
        </p>
        <div className='mt-2'>
          <Button wFull={false} padding='px-6' title={title} className=''/>
        </div>
      </div>
    </Link>
  );
};

export default DashboardMessage;
