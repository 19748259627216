import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import WrapInputLabel from 'common/components/wrap-input-label';
import { claimStatusEnum } from 'common/utils/enums';
import { useGetAllPlansQuery } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { isEmpty } from 'common/utils/isEmpty';
import SelectInput from 'common/components/select-input';
import StatusLabel from 'common/components/status-label';
import { useCreateClaimMutation } from 'redux/api/ciosUsersApi/ciosUsersApi';
import ApplyButtons from 'layouts/apply-buttons';
import {
  ApplyFormContainer,
  ApplyFormField,
  ApplyFormLayout,
} from 'common/layouts/apply-form';
import { Text } from '@chakra-ui/react';
import ApplyTitle from 'components/apply-shared/apply-title';
import ApplyContent from 'components/apply-shared/apply-content';

const StartClaim = () => {
  const navigate = useNavigate();
  // HOOKS
  const { data: plansData, isLoading: getPlansLoading } = useGetAllPlansQuery({
    status: [claimStatusEnum.ACTIVE],
  });
  const [createClaim, { data: claimData, isLoading: createClaimLoading }] =
    useCreateClaimMutation();
  const [planId, setPlanId] = useState(null);

  // HANDLERS
  const createPlansOptionMenu = (obj) => {
    // Check if obj is empty
    if (isEmpty(obj)) return [];

    // Remove items in obj if their status is not active
    const filteredItems = obj.filter(
      (item) => item.status === claimStatusEnum.ACTIVE
    );

    // Check if filteredItems is empty
    if (isEmpty(filteredItems)) return [];

    return filteredItems?.map(
      // eslint-disable-next-line no-unused-vars
      ({ createdAt, id, status, lease, ...res }) => ({
        label: (
          <div className='w-full flex py-1 justify-between flex-col sm:gap-2 sm:flex-row sm:items-center'>
            <div className='text-md font-medium'>Plan {id.toUpperCase()}</div>
            <div className='text-md flex gap-2 items-center w-full justify-between sm:w-auto'>
              {lease?.address && (
                <div className='text-md text-gray-500'>
                  {lease?.address?.streetAddress1}, {lease?.address?.city}
                </div>
              )}
              <StatusLabel status={status} size='sm' />
            </div>
          </div>
        ),
        value: id,
      })
    );
  };

  return (
    <div className='flex flex-col w-full h-full'>
      <ApplyTitle
        title='Select Plan for Claim'
        subtitle='Pick the plan for the lease you are submitting a claim for.'
        buttons={
          <ApplyButtons
            isValid={planId}
            moveOn={true}
            onNextClick={() => {
              createClaim({
                value: { planID: planId },
              })
                .unwrap()
                .then((x) => {
                  navigate(`/dashboard/apply-for-claims/${x?.data?.id}`);
                });
            }}
            disableBack
          />
        }
      />

      {/* Main Area */}
      <ApplyContent>
        {/* container */}
        <ApplyFormContainer>
          {/* select plan */}
          <ApplyFormLayout>
            <Text className='font-medium col-span-2'>
              Select Plan from List
            </Text>
            <ApplyFormField>
              <WrapInputLabel isRequired>Plan</WrapInputLabel>

              <SelectInput
                options={createPlansOptionMenu(plansData)}
                isDisabled={isEmpty(plansData) || getPlansLoading}
                placeholder={
                  getPlansLoading
                    ? 'Fetching...'
                    : isEmpty(plansData)
                      ? `No available plan, please create a new one.`
                      : 'Type to search for a plan by number'
                }
                name='planId'
                value={createPlansOptionMenu(plansData).find(
                  (selectedPlan) => selectedPlan.value === planId
                )}
                onChange={(selectedOption) => {
                  setPlanId(selectedOption.value);
                }}
                isOptionDisabled={(option) => {
                  const optionID = option.value;
                  const optionFullObject = plansData.find(
                    (plan) => plan.id === optionID
                  );
                  return optionFullObject?.status !== claimStatusEnum.ACTIVE;
                }}
              />
            </ApplyFormField>
          </ApplyFormLayout>
        </ApplyFormContainer>
      </ApplyContent>
    </div>
  );
};

export default StartClaim;
