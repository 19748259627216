import { useNavigate, useParams } from 'react-router-dom';
import { ProgressCard } from 'components/apply-shared/progress-card';
import ApplyForClaimLayout from 'layouts/claim-submit';
import {
  FaCheck,
  FaCheckToSlot,
  FaClipboardCheck,
  FaClipboardList,
  FaPaperPlane,
  FaReadme,
} from 'react-icons/fa6';
import { FaInfoCircle } from 'react-icons/fa';
import {
  ApplyAside,
  ApplyContainer,
  ApplyHeader,
  ApplyMain,
} from 'components/apply-shared/apply-form';
import { useGetSingleClaimQuery } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { claimTypeEnum, claimStatusEnum } from 'common/utils/enums';
import ReviewClaimLayout from 'layouts/claim-review';

const ReviewClaim = () => {
  const { claimId } = useParams();

  // HOOKS
  const navigate = useNavigate();
  const { data: claimData, isLoading: getSingleClaimLoading } =
    useGetSingleClaimQuery(claimId, { skip: false });

  // HANDLERS
  const handleReturn = () => {
    navigate(`/dashboard/claims/${claimId}`);
  };
  const handleGoToDashboard = () => {
    navigate('/dashboard/claims');
  };

  return (
    <>
      <ApplyContainer>
        <ApplyHeader onClick={handleGoToDashboard} />

        <ApplyMain>
          <ApplyAside
            // Return to claim if user wants to exit the review
            save={handleReturn}
            saveText={claimId && 'Return to Claim'}
          >
            {/* Standard resolution review */}
            {/* Never blurred */}
            {/* Complete when claim confirmedDate is set */}
            <ProgressCard
              CardIcon={FaClipboardCheck}
              title='Review Resolution'
              step={1}
              isBlurred={false}
              isCompleted={claimData?.submission?.confirmedDate}
            />

            {/* Final confirmation step with next steps; only shown for non-denied claims */}
            {/* Blurred when claim confirmedDate is not set */}
            {/* Complete when claim status is confirmed */}
            {claimData?.review?.dates?.moveOutStart && (
              <ProgressCard
                CardIcon={FaPaperPlane}
                title='Finalize Claim'
                step={2}
                isBlurred={!claimData?.submission?.confirmedDate}
                isCompleted={claimData?.status === claimStatusEnum.Confirmed}
              />
            )}
          </ApplyAside>

          {/* main section */}
          <ReviewClaimLayout claimId={claimId} claimData={claimData} />
        </ApplyMain>
      </ApplyContainer>
    </>
  );
};

export default ReviewClaim;
