import { Icon } from '@chakra-ui/react';
import Button from 'common/components/button';
import { FaBoxOpen } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from 'common/components/loading-spinner';

const EmptyClaims = ({ isLoading }) => {
  const navigate = useNavigate();
  return (
    <div className='w-full flex flex-col gap-6 py-20 items-center text-center rounded-lg border border-gray-200'>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Icon
          className='text-gray-500'
          boxSize={10}
          as={FaBoxOpen}
        />
      )}

      <div className='flex flex-col gap-2'>
        <h2 className='text-2xl font-display tracking-tight'>
          {isLoading ? 'Loading Your Claims' : 'Keep Track of Your Claims Here'}
        </h2>

        <p className='text-base text-gray-500 font-normal w-full'>
          {isLoading ? 'Please wait while we load your claims.' : "You don't have any claims yet. Once you have submitted claims, you'll be able to track their status and manage them here."}
        </p>
      </div>

      <Button
        title='Submit a Claim'
        onClick={() => navigate('/dashboard/claims/submit')}
        wFull={false}
      />
    </div>
  );
};

export default EmptyClaims;
