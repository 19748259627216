import { Flex, Tag, Text } from '@chakra-ui/react';
import { Radio } from '@chakra-ui/react';
import { planPaymentMode } from 'utils/enums';

const PaymentPlanCard = ({
  value = planPaymentMode.SUBSCRIPTION,
  amount,
  discount,
}) => {
  return (
    <Flex className='px-6 py-3 gap-6 w-full flex-col sm:flex-row sm:items-center sm:justify-between'>
      {/* cost section */}
      <Radio value={value} size='lg'>
        <Flex className='ml-3 w-full flex-col'>
          <Text className='text-base font-medium'>
            {value === planPaymentMode.SUBSCRIPTION
              ? 'Monthly Payments'
              : 'Upfront Payment'}
          </Text>
          <Text className='text-base text-gray-500'>
            {value === planPaymentMode.SUBSCRIPTION
              ? 'Pay in monthly installments'
              : 'Pay upfront for a discount'}
          </Text>
        </Flex>
      </Radio>

      {/* Price section */}
      <Flex className={`flex gap-3`}>
        {value === planPaymentMode.ONE_TIME && (
          <Tag colorScheme='blue' className='w-fit h-fit flex py-1 px-3 rounded-full text-sm font-medium capitalize order-last sm:order-first'>
            Save {(discount * 100)?.toFixed(2)}%
          </Tag>
        )}
        <Text>
          <span className='align-baseline text-xl font-medium'>${amount}</span>
          <span className='align-baseline text-md text-gray-500'>  /mo</span>
        </Text>        
      </Flex>
    </Flex>
  );
};

export default PaymentPlanCard;
