import { FaCheck } from 'react-icons/fa6';
import { FaXmark } from 'react-icons/fa6';
import {
  LOWERCASECASE_REGEX,
  NOT_STRICT_DIGIT_REGEX,
  UPPERCASE_REGEX,
} from 'utils/regex';

const PasswordRequirements = ({ password }) => {
  return (
    <ul className='flex flex-col gap-1 text-sm'>
      <li className='font-medium text-gray-500'>Password requirements:</li>

      {/* check if password is up to 8 chars */}
      <li
        className={`inline-flex items-center gap-2 ${
          password.length >= 8 && 'text-green-500'
        }`}
      >
        {password.length >= 8 ? (
          <FaCheck />
        ) : (
          <FaXmark />
        )}
        Must be at least 8 characters
      </li>

      {/* check if password contains atleast one uppercase letter */}
      <li
        className={`inline-flex items-center gap-2 ${
          password.match(UPPERCASE_REGEX) && 'text-green-500'
        }`}
      >
        {password.match(UPPERCASE_REGEX) ? (
          <FaCheck />
        ) : (
          <FaXmark />
        )}
        Must contain at least one UPPERCASE letter
      </li>

      {/* check if password contains atleast one lowercase letter */}
      <li
        className={`inline-flex items-center gap-2 ${
          password.match(LOWERCASECASE_REGEX) &&
          'text-green-500'
        }`}
      >
        {password.match(LOWERCASECASE_REGEX) ? (
          <FaCheck />
        ) : (
          <FaXmark />
        )}
        Must contain at least one lowercase letter
      </li>

      {/* check if password contains atleast one number */}
      <li
        className={`inline-flex items-center gap-2 ${
          password.match(NOT_STRICT_DIGIT_REGEX) &&
          'text-green-500'
        }`}
      >
        {password.match(NOT_STRICT_DIGIT_REGEX) ? (
          <FaCheck />
        ) : (
          <FaXmark />
        )}
        Must contain at least one number
      </li>
    </ul>
  );
};

export { PasswordRequirements };
