import LogoImage from 'common/components/logo-image';
import AuthAsideLayout from 'layouts/auth-aside';

const AuthMainLayout = ({ children }) => {
  return (
    <main className='h-screen w-screen mobile:min-h-screen flex flex-col gap-4 md:grid md:grid-cols-[2fr_3fr] overflow-hidden'>
      {/* aside */}
      <AuthAsideLayout />      

      <div className='flex md:hidden items-start m-8 px-6 w-full max-w-lg place-self-center'>
        <LogoImage dark={true} size='s'/>
      </div>

      {/* main section */}
      <div className='h-full relative flex flex-col items-center overflow-scroll md:px-8 md:py-16 max-w-xl place-self-center'>        
        {children}
      </div>
    </main>
  );
};

export default AuthMainLayout;
