/* eslint-disable no-unused-vars */
import { useRetrieveUserDetailsQuery } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { isEmpty } from 'common/utils/isEmpty';
import EmptyData from 'components/shared/empty-data';
import LoadingSpinner from 'components/shared/loading-spinner';
import ContactForm from 'components/settings/contact-form';
import PasswordForm from 'components/settings/password-form';

const AccountSettings = () => {
  // HOOKS
  const { data: userDetails, isLoading: getUserDetailsLoading } =
    useRetrieveUserDetailsQuery();

  return getUserDetailsLoading ? (
    <LoadingSpinner heightTailwind='h-[60dvh]' />
  ) : isEmpty(userDetails) ? (
    <EmptyData />
  ) : (
    <>
      {/* Contact Information */}
      <ContactForm user={userDetails} />

      {/* Reset Password */}
      <PasswordForm />
    </>
  );
};

export default AccountSettings;
