import AnchorLinkTag from 'components/shared/anchor-link-tag';
import { BsArrowRightShort } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import Button from 'common/components/button';
import BgLayout from 'common/components/bg';
import Tracker from 'components/auth/welcome/tracker';

const Welcome = () => {
  const navigate = useNavigate();

  return (
    <>
      <BgLayout>
        {/* texts */}
        <div className='flex flex-col text-center gap-6'>
          <h1 className='font-display tracking-tight text-4xl'>
            Welcome to ReLease
          </h1>

          <p className='text-base text-gray-500 font-normal'>
            Our flexible leasing platform gives you the freedom to move on your own terms. Sign your lease with the confidence of knowing you can move or cancel anytime, for any reason.
          </p>
        </div>

        {/* button */}
        <div className='flex w-full flex-col gap-3'>
          <Button title='Apply for a plan' linkId='applyForPlanBtn' onClick={() => navigate('/dashboard/plans/apply')} />        
          <Button
            theme='secondary'
            linkId='speakWithExpertBtn'
            title='Speak with an Expert'
            onClick={() => window.open('mailto:members@releaserent.com', '_blank')}
          />
        </div>
      </BgLayout>
      <Tracker />
    </>
  );
};

export default Welcome;
