import { useEffect } from 'react';
import { useRetrieveUserDetailsQuery } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { isEmpty } from 'common/utils/isEmpty';
import VerifyIdentity from 'components/settings/verify-identify';
import { Flex, Icon, Tag } from '@chakra-ui/react';
import { documentStatusEnum } from 'utils/enums';
import { HiCheckBadge } from 'react-icons/hi2';
import { FaXmark } from 'react-icons/fa6';

const VerificationSettings = () => {
  // HOOKS
  const { data: userDetails, isLoading } = useRetrieveUserDetailsQuery();

  useEffect(() => {}, [userDetails, isLoading]);

  return (
    <div className='w-full flex flex-col gap-12'>
      {/* title */}
      <Flex className='flex-col gap-4 items-start'>
        <div className='flex flex-col gap-1'>
          <h3 className='text-lg font-medium'>Verification</h3>
          <h5 className='text-gray-500 text-base font-normal'>
            Manage your identity verification status
          </h5>
        </div>
        {userDetails?.idVerification === documentStatusEnum.VERIFIED ? (
          <Tag
            className='pl-2 pr-3 gap-2 rounded-full text-sm'
            colorScheme='green'
          >
            <Icon as={HiCheckBadge} />
            Verified
          </Tag>
        ) : userDetails?.idVerification === documentStatusEnum.REJECTED ? (
          <Tag
            className='pl-2 pr-3 gap-2 rounded-full text-sm'
            colorScheme='red'
          >
            <Icon as={FaXmark} />
            Rejected
          </Tag>
        ) : userDetails?.documents?.length > 0 ? (
          <Tag
            className='pl-2 pr-3 gap-2 rounded-full text-sm'
            colorScheme='yellow'
          >
            <Icon as={HiCheckBadge} />
            Uploaded
          </Tag>
        ) : null}
      </Flex>

      {/* content */}
      <div className='w-full flex justify-center'>
        {!isEmpty(userDetails) && <VerifyIdentity user={userDetails} />}
      </div>
    </div>
  );
};

export default VerificationSettings;
