import { FaCheck, FaRegUser } from 'react-icons/fa';
import { Icon } from '@chakra-ui/react';
import { FaUser } from 'react-icons/fa6';

// Blurred - Future steps not yet available to member
// Completed - Step is already completed by member

const ProgressIcon = ({ isBlurred, isCompleted, CardIcon, zIndex, className }) => {
  return (
    <span
      className={`items-center h-9 w-9 flex justify-center border rounded-full ${zIndex} ${isCompleted ? 'bg-gray-200 border-gray-300' : 'bg-gray-100 border-gray-200'} ${isBlurred && 'border-gray-200'} ${className}`}
    >
      <Icon
        className={`text-lg ${isBlurred ? 'text-gray-300' : 'text-gray-500'}`}
        as={isCompleted ? FaCheck : CardIcon || FaUser}
        boxSize={4}
      />
    </span>
  ) 
}

const Card = ({ isBlurred, isCompleted, title, CardIcon, step, zIndex, className }) => {
  return ( 
    <div
      className={`w-full rounded-lg border border-gray-200 px-3 py-3 gap-3 flex items-center relative ${isBlurred && 'opacity-25 border-gray-200'} ${isCompleted ? 'bg-gray-50' : ''} ${className}`}
    >
      {/* CardIcon */}
      <ProgressIcon isBlurred={isBlurred} isCompleted={isCompleted} CardIcon={CardIcon} zIndex={zIndex} />

      {/* text */}
      <div className='flex flex-col'>
        <p className='text-sm text-gray-500'>Step {step}</p>
        <p className='text-base font-medium'>{title}</p>
      </div>
    </div>
  )
}

const ProgressCard = ({
  step,
  isBlurred,
  isCompleted,
  title,
  CardIcon,
}) => {
  return (
    <>
      {/* Desktop Version */}
      <Card isBlurred={isBlurred} isCompleted={isCompleted} title={title} CardIcon={CardIcon} step={step} className='mobile:hidden' />

      {/* Mobile Progress Bar */}
      <div className={`hidden mobile:flex w-full h-1 ${isCompleted ? 'bg-gray-200' : isBlurred ? 'bg-gray-200' : 'bg-black'}`} />
    </>
  );
};

export { ProgressCard, ProgressIcon };
