import LogoImage from 'common/components/logo-image';
import WrapInputLabel from 'common/components/wrap-input-label';
import { Form, Formik } from 'formik';
import { HiOutlineMail } from 'react-icons/hi';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import AuthLabel from 'components/shared/auth-label';
import Button from 'common/components/button';
import FormError from 'common/components/form-error';
import Input from 'common/components/input';
import PasswordInput from 'common/components/password-input';
import AuthMainLayout from 'layouts/auth-main';
import { useLoginMutation } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { setCredentials } from 'redux/features/auth/authSlice';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import Tracker from 'components/auth/login/tracker';
import { Link } from 'common/utils/user-tracker/react-router-dom';
import { ApplyFormField } from 'common/layouts/apply-form';

const Login = () => {
  // HOOKS
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [login, { isLoading: isLoginLoading }] = useLoginMutation();
  const {
    trackIdentity,
    trackFormSubmission,
    trackFormSubmissionSuccess,
    trackFormSubmissionError,
  } = useUserTracker();

  // DATA INITIALIZATION
  const locationState = location?.state?.from;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        email: '',
        password: '',
      }}
      onSubmit={(values) => {
        let valueCp = JSON.parse(JSON.stringify(values));
        valueCp.password = '*'.repeat(valueCp.password.length);
        trackFormSubmission(valueCp);
        login({
          email: values.email,
          password: values.password,
        })
          .unwrap()
          .then(({ data }) => {
            toast.info('Welcome back');
            trackIdentity(data?.user?.id || 'unknown-id', data?.user || {});
            trackFormSubmissionSuccess(valueCp);
            dispatch(
              setCredentials({
                isLoggedIn: true,
                token: data?.token,
                user: data?.user,
              })
            );
            const returnURL = locationState?.pathname + locationState?.search;
            const finalURL = returnURL ? returnURL : '/dashboard/home';

            navigate(finalURL);
          })
          .catch((error) => {
            toast.error(error?.data?.msg || 'Login failed!');
            trackFormSubmissionError(
              valueCp,
              error?.data?.msg || 'Login failed'
            );
          });
      }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email('invalid email address')
          .required('Email is required'),
        password: Yup.string().required('password is required'),
      })}
    >
      {({
        values,
        handleChange,
        errors,
        touched,
        handleBlur,
        isValid,
        setFieldTouched,
      }) => {
        return (
          <AuthMainLayout>
            <div className='px-6 md:px-8 flex flex-col gap-12'>
              {/* labels */}
              <div className='flex gap-3 mobile:gap-0 items-center'>
                <AuthLabel title='Log In' />
                <Link to='/signup' linkId='loginSignUpBtn'>
                  <AuthLabel title='Create Account' isActive={false} />
                </Link>
              </div>

              {/* Form */}
              <Form className='flex flex-col items-stretch w-full gap-9'>
                {/* caption */}
                <h1 className='text-4xl font-display tracking-tight'>Log Into Your ReLease Account</h1>

                {/* fields container */}
                <div className='w-full flex flex-col gap-6'>
                  {/* email fields */}
                  <ApplyFormField>
                    <WrapInputLabel isRequired>
                        Email Address:
                      </WrapInputLabel>
                    <Input
                      name='email'
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldTouched('email');
                        handleChange(e);
                      }}
                      value={values.email}
                      placeholderText='example@gmail.com'
                      Icon={HiOutlineMail}
                    />
                    <FormError name='email' />
                  </ApplyFormField>

                  {/* password field */}
                  <ApplyFormField>
                    <WrapInputLabel isRequired>
                        Password:
                      </WrapInputLabel>
                    <PasswordInput
                      name='password'
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldTouched('password');
                        handleChange(e);
                      }}
                      value={values.password}
                      placeholderText='Password'
                    />

                    {/* container for form error and forgot password link */}
                    <div className='w-full flex items-center justify-between gap-2'>
                      <FormError name='password' />

                      {/* forgot password */}
                      <Link
                        className='text-gray-500 text-md hover:underline'
                        to='/reset-password-request'
                      >
                        Forgot your password?
                      </Link>
                    </div>
                  </ApplyFormField>
                </div>

                {/* button */}
                <div className='flex flex-col gap-3'>
                  <Button
                    disabled={!isValid || isLoginLoading}
                    loading={isLoginLoading}
                    type='submit'
                    title='Log In'
                  />

                  <Button
                    theme='secondary'
                    title='Create a New Account'
                    onClick={() => navigate('/signup')}
                  />

                  <Tracker
                    values={values}
                    errors={errors}
                    touched={touched}
                    formName='login'
                  />
                </div>
              </Form>
            </div>
          </AuthMainLayout>
        );
      }}
    </Formik>
  );
};

export default Login;
