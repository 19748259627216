import StartClaim from 'components/claim-submit/start-claim';
import StartClaimEdit from 'components/claim-submit/start-claim-edit';
import StartClaimType from 'components/claim-submit/start-claim-type';

const ApplyForClaimLayout = ({ claimId, claimData }) => {
  // Logic to determin which component to render based on claim status
  // Claim is created if both claimId and claimData exist
  const claimCreated = claimId && claimData;

  // Claim needs to have type set if claim is created and claimType is not none
  const claimTypeSet = claimCreated && claimData.claimType !== 'none';

  // Claim needs to have submission set if claim is created and submission moveOutEnd is set
  const claimSubmissionSet = claimCreated && claimData?.submission?.moveOutEnd;

  return (
    <section className='h-full w-full flex flex-col items-center border border-gray-200 divide-y divide-gray-200 rounded-lg mobile:border-none mobile:rounded-none mobile:divide-gray-200'>
      {claimCreated ? (
        claimTypeSet ? (
          claimSubmissionSet ? (
            <StartClaimEdit claim={claimData} />
          ) : (
            <StartClaimEdit claim={claimData} />
          )
        ) : (
          <StartClaimType claim={claimData} />
        )
      ) : (
        <StartClaim />
      )}
    </section>
  );
};

export default ApplyForClaimLayout;
