import { Elements } from '@stripe/react-stripe-js';
import { stripePromise } from 'App';
import CheckoutForm from 'components/stripe/checkout-form';

const Payment = ({ clientSecret, returnUrl, isPaymentIntent, onClick }) => {

  // Set up styling and clientSecret
  const appearance = {
    theme: 'flat',
    variables: {
      colorPrimary: '#191923',
      colorBackground: '#ffffff',
      colorText: '#191923',
      colorDanger: '#df1b41',
      fontFamily: 'Inter, sans-serif',
      spacingUnit: '0.5rem',
      borderRadius: '0.5rem',
      fontSizeBase: '0.9rem',
      tabIconColor: '#6b7280',
      gridRowSpacing: '1.5rem',
    },
    rules: {
      '.Tab': {
        padding: '1rem', // Tailwind's p-4
        margin: '0.5rem 0.5rem', // Tailwind's m-2
        boxShadow: 'none',
      },
      '.Tab--selected': {
        border: '1px solid #191923',
        boxShadow: 'none',
      },
      '.Label': {
        fontFamily: 'inherit',
        fontSize: '0.875rem', // Tailwind's text-sm
        fontWeight: '500', // Tailwind's font-medium
        color: '#6b7280', // Tailwind's text-gray-500
        textTransform: 'capitalize',
        marginBottom: '0.5rem', // Tailwind's mb-2
      },
      '.Input': {
        padding: '8px 12px', // Matches your input padding
        border: '1px solid #e5e7eb', // Tailwind's border-gray-200
        borderRadius: '0.375rem', // Tailwind's rounded-md
        backgroundColor: '#ffffff', // Tailwind's bg-white
        boxShadow: 'none',
        fontSize: '1rem', // Tailwind's text-base
        fontWeight: '400', // Tailwind's font-normal
        color: '#191923',
        outline: 'none',
        transition: 'box-shadow 0.2s ease',
      },
      '.Input:focus': {
        borderColor: '#191923', // Tailwind's blue-500
        boxShadow: 'none',
      },
      '.Input::placeholder': {
        color: '#6b7280', // Tailwind's placeholder-gray-500
      },
      '.Error': {
        color: '#df1b41', // Matches your danger color
        fontSize: '0.875rem', // Tailwind's text-sm
      },
    },
  };

  const options = {
    clientSecret: clientSecret,
    appearance,
  }

  return (
    <Elements options={options} stripe={stripePromise}>
      <CheckoutForm onClick={onClick} returnUrl={returnUrl} isPaymentIntent={isPaymentIntent} />
    </Elements>
  );
};

export default Payment;
