import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import LoadingPageLayout from '../../../layouts/loading-page';
import {
  selectIsLoggedIn,
  selectToken,
  selectUserEmailConfirmed,
} from '../../../redux/features/auth/authSlice';
import { isEmpty } from 'common/utils/isEmpty';

const RouteGuard = () => {
  // STATES
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // HOOKS
  const location = useLocation();
  const navigate = useNavigate();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const token = useSelector(selectToken);
  const isEmailConfirmed = useSelector(selectUserEmailConfirmed);

  // DATA INITIALIZATION
  const currentPath = location.pathname;
  const authRoutes = [
    '/login',
    '/signup',
    '/confirm-account',
    '/reset-email',
    '/reset-password-request',
    '/reset-password-success',
  ];
  const confirmEmailRoute = ['/confirm-email'];
  const dashboardRoute = '/dashboard/home';
  const IsUserLoggedIn = isLoggedIn && !isEmpty(token);

  // HOOKS: Memoized Callback Function
  const authCheck = useCallback(() => {
    if (IsUserLoggedIn) {
      if (currentPath.includes(dashboardRoute) && !isEmailConfirmed) {
        setIsAuthenticated(false);
        navigate('/confirm-email', {
          state: { from: location },
          replace: true,
        });
      } else if (authRoutes.includes(currentPath)) {
        setIsAuthenticated(false);
        navigate(!isEmailConfirmed ? '/confirm-email' : '/dashboard/home', {
          replace: true,
        });
      } else if (confirmEmailRoute.includes(currentPath) && isEmailConfirmed) {
        setIsAuthenticated(false);
        navigate('/welcome', {
          replace: true,
        });
      } else {
        setIsAuthenticated(true);
      }
    } else if (!IsUserLoggedIn && !authRoutes.includes(currentPath)) {
      setIsAuthenticated(false);
      navigate('/login', {
        state: { from: location },
        replace: true,
      });
    } else {
      setIsAuthenticated(true);
    }
  }, [location]);

  // SIDE EFFECTS
  useEffect(() => {
    // Call the AuthCheck function to run here.
    authCheck();
  }, [location]);

  // RENDER
  if (isAuthenticated) {
    return <Outlet />;
  } else {
    <LoadingPageLayout />;
  }
};

export default RouteGuard;
