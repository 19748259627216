import React, { useEffect } from 'react';
import { IoIosSend, IoMdSettings } from 'react-icons/io';
import { RiErrorWarningFill } from 'react-icons/ri';
import DashboardCard from 'common/components/dashboard/dashboard-card';
import DashboardMessage from 'components/shared/dashboard-message';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import {
  useGetAllClaimsQuery,
  useGetAllPlansQuery,
  useGetDashboardMessagesQuery,
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import ContactCard from 'components/shared/contact-card';

const DashboardHome = () => {
  const { trackPageLoad, trackCustomEvent, setFormData } = useUserTracker();

  // For some reason I can't use the tracker element, causes weird page loading issues
  useEffect(() => {
    trackPageLoad('dashboardHome');
    setFormData({});
  }, []);
  // HOOKS
  const { data: claimsData, isLoading: isGetAllClaimsLoading } =
    useGetAllClaimsQuery();
  const { data: plansData, isLoading: getAllPlansLoading } =
    useGetAllPlansQuery();
  const { data: dashboardMessages, isLoading: getDashboardMessagesLoading } =
    useGetDashboardMessagesQuery();

  return (
    <DashboardWrapperLayout title='Home'>
      {dashboardMessages?.message && (

        // July 25 2024 - Replaced Taylor's rough box with a proper chakra-ui implementation for the activate card
        <DashboardMessage 
          Icon={RiErrorWarningFill}
          path={`/dashboard/plans/apply?step=5&status=pending&isSubmitted=true&planId=${dashboardMessages.planID}`}
          title='Activate Plan'
          description={dashboardMessages.message}
        />
      )}

      <div className='w-full flex flex-col gap-4'>
        <h2 className='text-lg font-medium'>Jump to Page</h2>
        <div className='w-full grid grid-cols-4 gap-8 mobile:grid-cols-2 mobile:gap-5'>
        {/* Plans Card */}
        <DashboardCard
          loading={getAllPlansLoading}
          title='Plans'
          count={plansData?.length ?? 0}
          path='/dashboard/plans'
          Icon={IoIosSend}
        />

        {/* Claims Card */}
        <DashboardCard
          loading={isGetAllClaimsLoading}
          title='Claims'
          count={claimsData?.length ?? 0}
          path='/dashboard/claims'
          Icon={RiErrorWarningFill}
        />

        {/* settings */}
        <DashboardCard
          title='Settings'
          count={0}
          path='/dashboard/settings'
          Icon={IoMdSettings}
        />
      </div>
      </div>
      
      {/* Contact Us Card */}
      <ContactCard 
        title='Contact Us'        
      >
        Questions about your plan or with claims?. Contact us by email, phone, or text and we can help you answer any questions you may have about purchasing a plan, making claims, and more.
      </ContactCard>

    </DashboardWrapperLayout>
  );
};

export default DashboardHome;
