/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from 'common/components/button';
import ConfirmActionModal from 'common/components/confirm-action-modal';
import BgLayout from 'common/components/bg';
import {
  useConfirmEmailMutation,
  useLazyRetrieveUserDetailsQuery,
  useResendEmailTokenMutation,
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import { logout, setUserDetails } from 'redux/features/auth/authSlice';
import { isEmpty } from 'common/utils/isEmpty';
import Tracker from 'components/auth/confirm-email/tracker';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';

const ConfirmEmail = () => {
  // STATES
  const [hasResentConfirmation, setHasResent] = useState(false);
  const [isLogoutModalOpen, setLogoutModalState] = useState(false);

  // HOOKS
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const sentEmailTkn = searchParams.get('token');
  const [resendEmailToken, { isLoading: isResendEmailLoading }] =
    useResendEmailTokenMutation();
  const {
    trackFormSubmission,
    trackFormSubmissionSuccess,
    trackFormSubmissionError,
  } = useUserTracker();
  const [
    confirmEmail,
    { isLoading: isConfirmEmailLoading, isSuccess: isConfirmEmailSuccess },
  ] = useConfirmEmailMutation();
  const [retrieveUser, retrieveUserResults] = useLazyRetrieveUserDetailsQuery();

  // HANDLERS
  const handleResendEmailConfirmation = () => {
    resendEmailToken()
      .unwrap()
      .then(() => {
        toast.info('Confirmation resent, please check your mail');
        setHasResent(true);
      })
      .catch((error) => {
        toast.error(error?.message || 'Failed to resend confirmation!');
      });
  };
  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
  };

  // SIDE EFFECTS
  useEffect(() => {
    if (!isEmpty(sentEmailTkn)) {
      // call resolve email confirmation
      trackFormSubmission({ token: sentEmailTkn });
      confirmEmail(sentEmailTkn)
        .unwrap()
        .then((response) => {
          trackFormSubmissionSuccess({ token: sentEmailTkn });
          toast.info(response?.message || 'Your email has been confirmed!', {
            toastId: 'emailConfirmSuccess',
          });
          setTimeout(() => {
            navigate('/welcome', {
              replace: true,
            });
          }, 500);
        })
        .catch((error) => {
          trackFormSubmissionError(
            { token: sentEmailTkn },
            error?.data?.msg || `Couldn't confirm your email address`
          );
          toast.error(error?.message || `Couldn't confirm your email address`, {
            toastId: 'confirmEmailError',
          });
        });
    }
  }, []);
  useEffect(() => {
    if (isConfirmEmailSuccess) {
      // retrieve user details
      retrieveUser()
        .unwrap()
        .then((userDetailsRes) => {
          dispatch(setUserDetails(userDetailsRes));
        })
        .catch((error) => {
          toast.error(error?.message || `Couldn't retrieve user details`, {
            toastId: 'userDetailsError',
          });
        });
    }
  }, [isConfirmEmailSuccess]);

  return (
    <>
      <BgLayout>
        {/* when user's mail isn't being confirmed */}
          { isConfirmEmailSuccess ? (
            <>
              {/* texts */}
              <div className='flex flex-col text-center gap-6'>
                <h2 className='font-display tracking-tight text-4xl mobile:text-2xl'>
                  Email Confirmed Succcessfully
                </h2>

                <p className='text-base text-gray-500 font-normal'>
                  You have successfully completed your registration by
                  confirming your email, Please proceed to the login page to
                  access yourd dashboard.
                </p>
              </div>

              {/* button */}
              <Button loading />
            </>
          ) : (
            // give users a chance to resend confirmation mail
            <>
              {/* texts */}
              <div className='flex flex-col text-center gap-6'>
                <h2 className='font-display tracking-tight text-4xl mobile:text-2xl'>
                  Confirm your Email
                </h2>

                <p className='text-base text-gray-500 font-normal'>
                  We have sent an email to you. Please check your inbox or
                  your spam folder and click the link in the email to confirm
                  your email address and complete your registration.
                </p>
              </div>

              {/* buttons */}
              <div className='w-full flex flex-col items-center justify-center gap-3'>
                <Button
                  onClick={handleResendEmailConfirmation}
                  linkId='resendConfirmEmailBtn'
                  loading={isResendEmailLoading}
                  disabled={hasResentConfirmation}
                  title={
                    hasResentConfirmation
                      ? 'Please Check Your Email'
                      : 'Resend Confirmation Email'
                  }
                />
                <Button
                  onClick={() => setLogoutModalState(true)}
                  theme='secondary'
                  linkId='logoutBtn'
                  title='Log Out'
                  loading={isConfirmEmailLoading}
                />
              </div>
            </>
          )}
      </BgLayout>

      {isLogoutModalOpen && (
        <ConfirmActionModal
          action='log out'
          onConfirmClick={handleLogout}
          onCancel={() => setLogoutModalState(false)}
        />
      )}
      <Tracker />
    </>
  );
};

export default ConfirmEmail;
