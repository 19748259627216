import { useSearchParams } from 'react-router-dom';
import WrapInputLabel from 'common/components/wrap-input-label';
import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  savePlansValues,
  selectPlansValues,
} from 'redux/features/plans/plansSlice';
import * as Yup from 'yup';
import ApplyButtons from 'layouts/apply-buttons';
import {
  useCreatePlanMutation,
  useFindPropertiesQuery,
  useUploadRequestUrlMutation,
  useUpdateSinglePlanMutation,
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import { isEmpty } from 'common/utils/isEmpty';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import FormError from 'common/components/form-error';
import { extractErrorMsg } from 'utils/errors';
import {
  ApplyFormContainer,
  ApplyFormField,
  ApplyFormLayout,
} from 'common/layouts/apply-form';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Text,
} from '@chakra-ui/react';
import SelectInput from 'common/components/select-input';
import ContactCard from 'components/shared/contact-card';
import ApplyTitle from 'components/apply-shared/apply-title';
import ApplyContent from 'components/apply-shared/apply-content';
import { propertyStatusEnum } from 'utils/enums';

const PlanStepSelectProperty = ({
  onBackClick,
  stepNumber,
  onNextClick,
  clearSearchParams,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const planId = searchParams.get('planId');
  const isEdit = searchParams.get('isEdit');

  // check for planId
  // HANDLERS
  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  const createPropertyOptionMenu = (obj) => {
    if (isEmpty(obj)) return [];

    return obj?.map(
      // eslint-disable-next-line no-unused-vars
      ({ createdAt, id, name, address, ...res }) => ({
        label: (
          <div className='w-full flex gap-2 justify-between'>
            <div className='text-md font-medium'>{name || 'Building'}</div>
            <div className='text-md text-gray-500'>
              {address?.streetAddress1}, {address?.city}
            </div>
          </div>
        ),
        value: id,
      })
    );
  };

  // HOOKS
  const savedPlansValue = useSelector(selectPlansValues);
  const dispatch = useDispatch();
  const [
    // eslint-disable-next-line no-unused-vars
    createAPlan,
    { isLoading: createPlanLoading, isSuccess: isCreatePlanSuccess },
  ] = useCreatePlanMutation();
  const [
    // eslint-disable-next-line no-unused-vars
    updatePlan,
    { isLoading: updatePlanLoading, isSuccess: isUpdatePlanSuccess },
  ] = useUpdateSinglePlanMutation();

  const {
    data: propertiesQuery,
    isError: isFindPropertiesError,
    error: getPropertyError,
    isLoading: findPropertiesLoading,
  } = useFindPropertiesQuery({
    sort: '-createdAt',
    status: [propertyStatusEnum.OPTIN, propertyStatusEnum.OPTOUT, propertyStatusEnum.REQUIRED],
  });

  const [uploadRequestUrl] = useUploadRequestUrlMutation();
  // eslint-disable-next-line no-unused-vars
  const {
    trackFormSubmission,
    trackFormSubmissionSuccess,
    trackFormSubmissionError,
    trackCustomEvent,
  } = useUserTracker();

  // SIDE EFFECTS
  useEffect(() => {
    if (isCreatePlanSuccess || isUpdatePlanSuccess) {
      clearSearchParams();
    }
  }, [isCreatePlanSuccess, isUpdatePlanSuccess]);

  // Not sure why we implemented this feature, but it exists
  // const fieldsDisabled = !isEmpty(savedPlansValue) && !isEdit;
  const fieldsDisabled = false;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        property: savedPlansValue?.property,
      }}
      validationSchema={Yup.object().shape({
        property: Yup.string().required('Please select a property'),
      })}
      onSubmit={(values) => {
        let submissionValues = {
          property: values?.property,
        };

        let mutation = createAPlan;
        if (planId) {
          submissionValues.planId = planId;
          mutation = updatePlan;
        } else if (savedPlansValue.id) {
          submissionValues.planId = savedPlansValue.id;
          mutation = updatePlan;
        }

        trackFormSubmission(submissionValues);

        mutation(submissionValues)
          .unwrap()
          .then((data) => {
            dispatch(savePlansValues(data || {}));
            onNextClick();
            trackFormSubmissionSuccess(submissionValues);
          })
          .catch((error) => {
            const errMsg = extractErrorMsg(error, 'Update Failed');
            toast.error(errMsg);
            trackFormSubmissionError(submissionValues, errMsg);
          });
      }}
    >
      {({
        handleBlur,
        handleChange,
        setFieldValue,
        values,
        isValid,
        dirty: isDirty,
        handleSubmit,
        setFieldTouched,
      }) => {
        return (
          <Form
            onSubmit={(e) => e.preventDefault()}
            className='flex flex-col w-full h-full'
          >
            <ApplyTitle
              title='Select Property'
              subtitle='Pick the property you are renting.'
              buttons={
                <ApplyButtons
                  loading={createPlanLoading || updatePlanLoading}
                  isValid={isValid && (isDirty || savedPlansValue?.property)}
                  handleSubmit={isDirty ? handleSubmit : onNextClick}
                  moveOn={fieldsDisabled}
                  onNextClick={onNextClick}
                  disableBack
                />
              }
            />

            {/* main content */}
            <ApplyContent>
              {/* container */}
              <ApplyFormContainer>
                {/* Select Property */}
                <ApplyFormLayout>
                  <h2 className='font-medium col-span-2'>
                    Select Building from List
                  </h2>
                  <ApplyFormField>
                    <WrapInputLabel isRequired>Building</WrapInputLabel>
                    <SelectInput
                      options={createPropertyOptionMenu(propertiesQuery)}
                      isDisabled={
                        isEmpty(propertiesQuery) || findPropertiesLoading
                      }
                      isSearchable={false}
                      placeholder={
                        findPropertiesLoading
                          ? 'Fetching...'
                          : isEmpty(propertiesQuery)
                          ? `No available buildings`
                          : 'Select building from the list'
                      }
                      name='property'
                      value={createPropertyOptionMenu(propertiesQuery).find(
                        (option) => option.value === values?.property
                      )}
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          setFieldValue('property', selectedOption.value).then(
                            () => {
                              setFieldTouched('property');
                            }
                          );
                        } else {
                          // Handle case when select is cleared
                          setFieldValue('property', null).then(() => {
                            setFieldTouched('property');
                          });
                        }
                      }}
                      onBlur={handleBlur}
                    />
                    <FormError name='property' />
                  </ApplyFormField>
                </ApplyFormLayout>

                <Accordion
                  // defaultIndex={!savedPlansValue.property && [0]}
                  allowToggle
                  className='border rounded-md mt-6'
                >
                  <AccordionItem className='border-t-0 border-b rounded-b-md'>
                    <AccordionButton className='flex w-full gap-2 justify-between py-3'>
                      <Text className='font-medium text-base text-left'>
                        Can't find your building in the list?
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel className='border-t pt-5'>
                      <Text className='text-base mb-4 text-gray-500'>
                        If you can't find your property in the list above,
                        please provide the name and address of your building to
                        us in an email.
                      </Text>
                      {/* Can't find property */}
                      <ContactCard small />
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </ApplyFormContainer>

              {/* <Tracker
                    values={values}
                    errors={errors}
                    touched={touched}
                    formName='stepThree'
                  /> */}
            </ApplyContent>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PlanStepSelectProperty;
