import { useEffect, useMemo, useState } from 'react';
import { POLLING_INTERVAL, planStatusEnum } from 'common/utils/enums';
import PlansTable from 'components/plans/plans-table';
import Button from 'common/components/button';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import TablePaginateWrapper from 'common/layouts/table-paginate-wrapper';
import {
  useGetAllPlansQuery,
  useRetrieveUserDetailsQuery,
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import { isEmpty } from 'common/utils/isEmpty';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import useTracker from 'common/utils/user-tracker/useTracker';
import EmptyPlans from 'components/empty-pages/empty-plans';
import { useNavigate } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';
import PlanInfoCard from 'components/plans/plan-info-card';
import DownloadPdfButton from 'components/plans/download-button';
import CTA_Buttons from 'common/components/cta-buttons';
import ContactCard from 'components/shared/contact-card';

const Plans = () => {
  // DATA INITIALIZATION
  const pageSize = 10;
  const navigate = useNavigate();

  // STATES
  const [currentPage, setCurrentPage] = useState(1);
  const [pollingInterval, setPollingInterval] = useState(
    () => POLLING_INTERVAL
  );

  // HOOKS
  const { data: plansData, isLoading: getAllPlansLoading } =
    useGetAllPlansQuery(undefined, {
      pollingInterval: pollingInterval,
      sort: '-createdAt',
      limit: pageSize,
    });
  const { trackLink } = useUserTracker();
  useTracker({ pageId: 'plansList' });
  const [minPageLimit, maxPageLimit] = useMemo(() => {
    const minPageLimit = (currentPage - 1) * pageSize;
    const maxPageLimit = currentPage * pageSize - 1;
    return [minPageLimit, maxPageLimit];
  }, [currentPage]);
  const { isSuccess: isRetrieveUserDetailsSuccess, data: userDetails } =
    useRetrieveUserDetailsQuery();

  // USE EFFECT
  useEffect(() => {
    if (isEmpty(plansData)) {
      setPollingInterval(0);
    } else {
      setPollingInterval(POLLING_INTERVAL);
    }
  }, [plansData]);

  // Filter plansData down to only the plans that are not active, cancelled, or complete.
  const filteredPlansData = useMemo(() => {
    if (isEmpty(plansData)) return [];
    return plansData.filter(
      (plan) =>
        plan.status !== planStatusEnum.CANCELLED &&
        plan.status !== planStatusEnum.ACTIVE &&
        plan.status !== planStatusEnum.COMPLETE
    );
  }, [plansData]);

  // Filter plansDAta down to only the active plans
  const activePlans = useMemo(() => {
    if (isEmpty(plansData)) return [];
    return plansData.filter((plan) => plan.status === planStatusEnum.ACTIVE);
  }, [plansData]);

  // Set up cta buttons for the header
  const ctaButtons = (
    <CTA_Buttons
      onPrimaryClick={() => navigate('/dashboard/plans/apply')}
      onSecondaryClick={() => window.open('mailto:members@releaserent.com')}
      primaryTitle='Apply for a New Plan'
      secondaryTitle='Chat with an Expert'
    />
  );

  return (
    <DashboardWrapperLayout title='Plans' buttons={ctaButtons}>
      <div className='flex flex-col w-full gap-12 mobile:h-full'>
        {getAllPlansLoading ? (
          <EmptyPlans isLoading />
        ) : isEmpty(filteredPlansData) && isEmpty(activePlans) ? (
          <EmptyPlans />
        ) : (
          <>
            {/* active plans */}
            {!isEmpty(activePlans) && (
              <Flex className='flex-col gap-6'>
                {/* plan heading and status */}
                <h2 className='text-lg font-medium'>Your Active Plans</h2>
                {activePlans.map((plan, index) => (
                  <Flex
                    index={index}
                    className='flex-col gap-4 w-full p-4 rounded-lg border border-gray-200'
                  >
                    <PlanInfoCard plan={plan} member={userDetails} />

                    <Flex className='justify-start gap-3 mobile:flex-col'>
                      <Button
                        className='cursor-pointer hover:opacity-80 transition-all'
                        onClick={() => navigate(`/dashboard/plans/${plan.id}`)}
                        title='View Plan Details'
                        wFull={false}
                      />
                      {plan.id && (
                        <DownloadPdfButton planId={plan.id} wFull={false} />
                      )}
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            )}

            {/* applications */}
            {!isEmpty(filteredPlansData) && (
              <Flex className='flex-col gap-6'>
                {/* plan heading and status */}
                <h2 className='text-lg font-medium'>Your Applications</h2>

                {/* table */}
                <TablePaginateWrapper
                  pageSize={pageSize}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  totalCount={filteredPlansData?.length}
                >
                  <PlansTable
                    plans={filteredPlansData?.slice(minPageLimit, maxPageLimit)}
                  />
                </TablePaginateWrapper>
              </Flex>
            )}
          </>
        )}

        {/* help */}
        <ContactCard title='Need Help With a Plan?'>
          Contact us by email, phone, or text and we can help you answer any
          questions you may have about your plan.
        </ContactCard>
      </div>
    </DashboardWrapperLayout>
  );
};

export default Plans;
