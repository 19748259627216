import {
  Card,
  Text,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Icon,
} from '@chakra-ui/react';
import AnchorLinkTag from 'components/shared/anchor-link-tag';
import { FaCircleXmark, FaCircleCheck } from 'react-icons/fa6';
import ContactCard from 'components/shared/contact-card';

// Coverages and Exclusions props allow individual cards to be turned off if desired

const DisclosureItem = ({ InnerIcon, title, children }) => {
  return (
    <AccordionItem className='border-none'>
      <AccordionButton className='hover:bg-gray-100 px-3'>
        <div className='flex items-center gap-4 w-full text-left text-base font-medium'>
          <Icon as={InnerIcon} boxSize={3} className='text-gray-500' />
          {title}
        </div>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel className='pt-0 pb-3 pl-10 text-sm text-gray-500'>
        {/* Options */}
        {children}
      </AccordionPanel>
    </AccordionItem>
  );
};

const DisclosureCard = ({ coverages = true, exclusions = true }) => {
  return (
    <>
      {/* coverages */}
      {coverages && (
        <Flex className='flex-col gap-6'>
          <Flex className='flex-col gap-1'>
            <h2 className='text-lg font-medium'>
              What cancellation costs are covered?
            </h2>
            <Text className='text-base text-gray-500 mb-1'>
              Your ReLease plan covers a variety of costs that you may be
              charged by your landlord as a result of cancelling your lease.{' '}
              <AnchorLinkTag hrefPath='https://www.releaserent.com/legal/lease-flexibility-coverage-terms-conditions'>
                Learn more about what's covered in your plan
              </AnchorLinkTag>
              .
            </Text>
          </Flex>

          <Flex className='flex mobile:flex-col gap-6 w-full'>
            <Flex className='flex-col w-full gap-2 justify-start'>
              <h3 className='text-base font-medium'>What's Covered</h3>
              <Accordion
                allowMultiple
                className='flex w-full flex-col border border-gray-200 rounded-md overflow-hidden'
              >
                <DisclosureItem
                  InnerIcon={FaCircleCheck}
                  title='Outstanding Rent'
                >
                  ReLease will cover any outstanding rent owed on the same
                  schedule as in your lease agreement until a replacement tenant
                  is found, up to your coverage limit.
                </DisclosureItem>
                <DisclosureItem
                  InnerIcon={FaCircleCheck}
                  title='Cancellation Fees'
                >
                  ReLease will cover any cancellation fees owed due to a covered
                  cancellation, up to your coverage limit.
                </DisclosureItem>
                <DisclosureItem
                  InnerIcon={FaCircleCheck}
                  title='Lease Buyout Fees'
                >
                  If available, ReLease may cover the lease buyout fees
                  specified in your lease in lieu of Outstanding Rent and
                  Cancellation Fees, up to your coverage limit.
                </DisclosureItem>
              </Accordion>
            </Flex>
            <Flex className='flex-col w-full gap-2 justify-start'>
              <h3 className='text-base font-medium'>What's Not Covered</h3>
              <Accordion
                allowMultiple
                className='flex w-full flex-col border border-slate-300 rounded-md overflow-hidden'
              >
                <DisclosureItem
                  InnerIcon={FaCircleXmark}
                  title='Leasing Concessions'
                >
                  ReLease doesn't cover any leasing concessions, incentives,
                  discounts, or offers that you have to repay as part of a
                  cancellation.
                </DisclosureItem>
                <DisclosureItem
                  InnerIcon={FaCircleXmark}
                  title='Security Deposits'
                >
                  ReLease doesn't cover any security deposits or security
                  deposit replacement fees that you may have deducted as part of
                  a cancellation.
                </DisclosureItem>
              </Accordion>
            </Flex>
          </Flex>
        </Flex>
      )}

      {/* exclusions */}
      {exclusions && (
        <Flex className='flex-col gap-6'>
          <Flex className='flex-col gap-1'>
            <h2 className='text-lg font-medium'>
              What cancellations are excluded from coverage?
            </h2>
            <Text className='text-base text-gray-500 mb-1'>
              Your ReLease plan covers cancellations for any reason, including
              job changes, home purchases, illness, and more, with a few
              exceptions.{' '}
              <AnchorLinkTag hrefPath='https://www.releaserent.com/legal/lease-flexibility-coverage-terms-conditions'>
                Learn more about what's covered in your plan
              </AnchorLinkTag>
              .
            </Text>
          </Flex>

          {/* plan heading and status */}
          <Flex className='flex mobile:flex-col gap-8 w-full'>
            <Flex className='flex-col w-full gap-2 justify-start'>
              <h3 className='text-base font-medium'>
                Examples of Covered Cancellations
              </h3>
              <Accordion
                allowMultiple
                className='flex w-full flex-col border border-gray-200 rounded-md overflow-hidden'
              >
                <DisclosureItem InnerIcon={FaCircleCheck} title='Illness'>
                  ReLease covers your cancellation if you're unable to live in
                  your apartment due to illness or injury, including if you need
                  to move to a care facility.
                </DisclosureItem>
                <DisclosureItem InnerIcon={FaCircleCheck} title='Job Changes'>
                  ReLease covers your cancellation if you need to move as part
                  of a job relocation, whether it's for your current employer or
                  for a new employer you're starting at.
                </DisclosureItem>
                <DisclosureItem
                  InnerIcon={FaCircleCheck}
                  title='Home Purchases'
                >
                  Thinking about buying a new home but not sure when it'll
                  close? ReLease covers your cancellation if you're cancelling
                  mid-lease to purchase a new home.
                </DisclosureItem>
                <DisclosureItem
                  InnerIcon={FaCircleCheck}
                  title='School Transfers'
                >
                  If you're a student transfering schools or leaving school
                  altogether, ReLease covers your cancellation so you're not
                  stuck paying for two apartments at the same time.
                </DisclosureItem>
              </Accordion>
            </Flex>
            <Flex className='flex-col w-full gap-2 justify-start'>
              <h3 className='text-base font-medium'>Excluded Cancellations</h3>
              <Accordion
                allowMultiple
                className='flex w-full flex-col border border-gray-200 rounded-md overflow-hidden'
              >
                <DisclosureItem
                  InnerIcon={FaCircleXmark}
                  title='Premeditated Actions'
                >
                  ReLease can't cover any cancellations due to premeditated
                  actions, including but not limited to actions, decisions, or
                  situations that you intentionally plan or consciously execute
                  with prior, undisclosed knowledge before purchase.
                </DisclosureItem>
                <DisclosureItem
                  InnerIcon={FaCircleXmark}
                  title='Landlord Cancellations'
                >
                  ReLease can't cover any cancellation or termination of the
                  lease agreement by your landlord for any reason, including the
                  result of a force majeure event.
                </DisclosureItem>
                <DisclosureItem
                  InnerIcon={FaCircleXmark}
                  title='Unlawful Tenancies'
                >
                  ReLease can't cover any cancellations if your lease is found
                  to be illegal at any point during the coverage period, or any
                  other circumstances where your tenancy is deemed unlawful,
                  including if you or another resident is lawfully evicted.
                </DisclosureItem>
              </Accordion>
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  );
};

const DisclosureSection = () => {
  return (
    <Accordion allowMultiple>
      <AccordionItem className='border-none'>
        <AccordionButton className='border border-gray-200 rounded-md px-6 py-4'>
          <Flex className='flex-col gap-1 w-full items-left text-left'>
            <h2 className='text-lg font-medium'>
              Have questions about coverage?
            </h2>
            <Text className='text-base text-gray-500'>
              Learn more about the coverages included in your plan and get help
              with any questions you may have.
            </Text>
          </Flex>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel className='flex flex-col gap-12 px-6 mt-6'>
          <DisclosureCard />
          <ContactCard />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export { DisclosureCard, DisclosureSection };
