import { Link, useParams } from 'react-router-dom';
import EmptyData from 'components/shared/empty-data';
import LoadingSpinner from 'components/shared/loading-spinner';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import { useGetSingleClaimQuery } from 'redux/api/ciosUsersApi/ciosUsersApi';
import {
  claimPaymentPreferenceEnum,
  claimTypeEnum,
  claimStatusEnum,
} from 'common/utils/enums';
import { formatDateToHumanTime } from 'common/utils/formDateToHumanTime';
import { isEmpty } from 'common/utils/isEmpty';
import ContactCard from 'components/shared/contact-card';
import { Flex, Text } from '@chakra-ui/react';
import { InfoCard, InfoField, InfoGrid } from 'common/components/info-card';
import Button from 'common/components/button';
import { useNavigate } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa6';
import { ProgressIcon } from 'components/apply-shared/progress-card';

const ClaimDetails = () => {
  // HOOKS
  const { claimId } = useParams();
  const { data: singleClaim, isLoading: getSingleClaimLoading } =
    useGetSingleClaimQuery(claimId);

  // DATA INITIALIZATION
  const navigate = useNavigate();
  const statusMessage = {
    [claimStatusEnum.SUBMITTED]: 'Your claim has been submitted successfully',
    [claimStatusEnum.iN_REVIEW]:
      'Your claim is under review and the result will be emailed to you within 2 business days. Please check the progress here or your email for updates.',
    [claimStatusEnum.APPROVED]:
      'Your claim has been approved. Please read the  next steps and confirm.',
    [claimStatusEnum.CONFIRMED]:
      'Your claim has been confirmed and is now finalized. If you are moving out, you will receive move-out instructions shortly.',
    [claimStatusEnum.RESOLVED]:
      singleClaim?.status === claimStatusEnum.CONFIRMED
        ? 'Your claim has been resolved.'
        : 'Your claim has been resolved. Please review the resolution and confirm.',
    [claimStatusEnum.COMPLETED]:
      'Your claim is now complete. Please save the details for your records.',
  };

  // Check if the claim has been submitted before
  const hasSubmitted = singleClaim?.statusChanges?.some(
    (statusChange) => statusChange.status === claimStatusEnum.SUBMITTED
  );

  return (
    <DashboardWrapperLayout title='Claim Details'>
      <Flex className='flex-col w-full gap-12 bg-white'>
        {/* body content */}
        {getSingleClaimLoading ? (
          <LoadingSpinner />
        ) : isEmpty(singleClaim) ? (
          <EmptyData />
        ) : (
          <>
            {/* Progress */}
            {singleClaim?.statusChanges?.length > 0 && (
              <InfoCard title='Claim Progress'>
                <InfoGrid>
                  <Flex className='w-full flex-col gap-1 py-1 col-span-4'>
                    {singleClaim?.statusChanges?.map((statusChange, index) => (
                      <>
                        <Flex key={index} className='w-full gap-4 items-start'>
                          <Flex className='flex-col h-full items-center'>
                            <ProgressIcon
                              CardIcon={FaCheck}
                              className='px-4 py-4 mt-1'
                            />
                            {index < singleClaim?.statusChanges?.length - 1 && (
                              <div className='h-full min-h-[2rem] mt-2 flex w-10 justify-center'>
                                <div className='h-full border-l border-gray-200' />
                              </div>
                            )}
                          </Flex>
                          <Flex className='w-full gap-1 items-start flex-col mb-4'>
                            <Flex className='w-full gap-3 justify-between items-center'>
                              <Text className='text-base font-medium capitalize'>
                                {statusChange.status}
                              </Text>
                              <Text className='text-sm text-gray-500 min-w-fit mr-2'>
                                {formatDateToHumanTime(statusChange.updatedAt)}
                              </Text>
                            </Flex>
                            <Text className='text-sm'>
                              {statusMessage[statusChange.status]}
                            </Text>
                            {/* Show button to view completed review if the status is resolved */}
                            {statusChange.status === claimStatusEnum.RESOLVED &&
                            singleClaim?.status !==
                              claimStatusEnum.CONFIRMED ? (
                              <Button
                                title='View Completed Review'
                                onClick={() =>
                                  navigate(
                                    `/dashboard/claims/${singleClaim?.id}/review`
                                  )
                                }
                                className='my-2'
                                wFull={false}
                              />
                            ) : null}
                            {statusChange.status ===
                            claimStatusEnum.CONFIRMED ? (
                              <Button
                                title='View Confirmed Resolution'
                                onClick={() =>
                                  navigate(
                                    `/dashboard/claims/${singleClaim?.id}/review`
                                  )
                                }
                                className='mt-2'
                                wFull={false}
                                theme='secondary'
                              />
                            ) : null}
                          </Flex>
                        </Flex>
                      </>
                    ))}
                  </Flex>
                </InfoGrid>
              </InfoCard>
            )}

            {/* Submitted Information */}
            {hasSubmitted && (
              <InfoCard title='Submitted Claim Details'>
                {/* shared information for all claim types */}
                <InfoGrid>
                  <InfoField title='Claim Number' className='col-span-2'>
                    <span className='uppercase'>{singleClaim?.id}</span>
                  </InfoField>
                  <InfoField title='Plan ID' className='col-span-2'>
                    <Link
                      className='underline hover:opacity-50'
                      to={`/dashboard/plans/${singleClaim?.planId?.id}`}
                    >
                      <span className='uppercase'>
                        {singleClaim?.planId?.id}
                      </span>
                    </Link>
                  </InfoField>
                  <InfoField title='Lease' className='col-span-4'>
                    {singleClaim?.planId?.lease?.address?.streetAddress1}
                    {singleClaim?.planId?.lease?.address?.streetAddress2 && ' '}
                    {singleClaim?.planId?.lease?.address?.streetAddress2},{' '}
                    {singleClaim?.planId?.lease?.address?.city},{' '}
                    {singleClaim?.planId?.lease?.address?.state},{' '}
                    {singleClaim?.planId?.lease?.address?.postalCode}
                  </InfoField>
                </InfoGrid>

                <InfoGrid>
                  <InfoField title='Claim Type' className='col-span-4'>
                    {singleClaim?.claimType === claimTypeEnum.cancelAnytime
                      ? 'Cancel my upcoming lease'
                      : 'Move out early and cancel the reminder of my lease'}
                  </InfoField>
                </InfoGrid>

                <InfoGrid>
                  {/* Only show date fields when it's actually relevant */}
                  {singleClaim?.claimType === claimTypeEnum.leaveAnytime ? (
                    <>
                      <InfoField
                        title='Earliest Move Out Date'
                        className='col-span-2 sm:col-span-1'
                      >
                        {formatDateToHumanTime(
                          singleClaim?.submission?.moveOutStart
                        )}
                      </InfoField>
                      <InfoField
                        title='Latest Move Out Date'
                        className='col-span-2 sm:col-span-1'
                      >
                        {formatDateToHumanTime(
                          singleClaim?.submission?.moveOutEnd
                        )}
                      </InfoField>
                    </>
                  ) : null}
                  <InfoField
                    title='Payment Prference'
                    className='col-span-2 sm:col-span-1'
                  >
                    {singleClaim?.submission?.paymentPreference ===
                    claimPaymentPreferenceEnum.DIRECT
                      ? 'Direct'
                      : 'Reimbursement'}
                  </InfoField>
                </InfoGrid>

                <InfoGrid>
                  <InfoField title='Reason for Move Out' className='col-span-4'>
                    {singleClaim?.submission?.moveReason}
                  </InfoField>
                </InfoGrid>
              </InfoCard>
            )}
          </>
        )}

        {/* Contact Section */}
        <ContactCard title='Have Questions About Your Claim?'>
          {[
            claimStatusEnum.DRAFT,
            claimStatusEnum.SUBMITTED,
            claimStatusEnum.IN_REVIEW,
          ].includes(singleClaim?.status) ? (
            <Text>
              We are now reviewing your claims based on our terms and notify you
              of the decision through the app. If you have any questions during
              the process, feel free to reach out to our customer service team
              anytime with the information below.
            </Text>
          ) : (
            <Text>
              If you have any questions about your claim, please reach out to
              our customer service team anytime with the information below.
            </Text>
          )}
        </ContactCard>
      </Flex>
    </DashboardWrapperLayout>
  );
};

export default ClaimDetails;
