import { useState } from 'react';
import { TiHome } from 'react-icons/ti';
import { Link } from 'react-router-dom';
import AccountSettings from 'components/settings/account-settings';
import Tabs from 'components/settings/tabs';
import VerificationSettings from 'components/settings/verification-settings';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import { settingsTab } from 'utils/enums';
import { Flex, Icon } from '@chakra-ui/react';
import { FaChevronRight } from 'react-icons/fa6';

const Settings = () => {
  // STATES
  const [currentTab, setCurrentTab] = useState(() => settingsTab.ACCOUNT);
  return (
    <DashboardWrapperLayout 
      title='Settings'
      buttons={
        <Tabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
      }
    >
      <Flex className='flex flex-col w-full gap-12'>
        {/* Content */}
        {currentTab === settingsTab.ACCOUNT ? (
          <AccountSettings />
        ) : currentTab === settingsTab.VERIFICATION ? (
          <VerificationSettings />
        ) : null}
      </Flex>
    </DashboardWrapperLayout>
  );
};

export default Settings;
