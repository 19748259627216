const ApplyTitle = ({ title, subtitle, buttons }) => {
  return (
    <div className='flex gap-4 w-full p-6 border-b border-gray-200 mobile:border-none mobile:px-2 mobile:pt-0 mobile:pb-7 mobile:flex-col'>
      <div className='flex flex-col w-full gap-1'>
        <h1 className='text-2xl font-display tracking-tight'>
          {title}
        </h1>
        <p className='text-base text-gray-500'>
          {subtitle}
        </p>
      </div>

      {/* Buttons */}
      {buttons}
    </div>
  );
};

export default ApplyTitle;
