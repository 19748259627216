import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ModalBgLayout from 'common/layouts/modal-bg';
import { useDeleteAPlanMutation } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { clearPlansDraft } from 'redux/features/plans/plansSlice';
import Button from 'common/components/button';
import { Flex, Icon } from '@chakra-ui/react';

const CancelApplicationModal = ({ closeModal, planId }) => {
  // HOOKS
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteAPlan, { isLoading }] = useDeleteAPlanMutation();

  // HANDLERS
  const handleCancelPlan = () => {
    deleteAPlan(planId)
      .unwrap()
      .then(() => {
        deleteAPlan(planId);
        navigate('/dashboard/plans');
      })
      .catch((error) => {
        toast.error(error?.message || `Couldn't cancel plan`);
      });
    dispatch(clearPlansDraft());
  };

  return (
    <ModalBgLayout>
      <Flex className='flex-col gap-6'>
        {/* icon and texts*/}
        <Flex className='gap-1 flex-col'>
          <h2 className='text-lg font-medium'>Cancel Application</h2>
          <p className='text-base text-gray-500'>
            Cancelling the application will delete the application and you will
            need to start again. Proceed if you want to cancel and delete the plan
            application.
          </p>            
        </Flex>

        {/* buttons */}
        <Flex className='gap-3 flex-col sm:flex-row sm:items-start'>
          <Button
            loading={isLoading}
            onClick={handleCancelPlan}
            title='Yes, Cancel Application'
            wFull={false}
          />

          <Button onClick={closeModal} title='Return to Application' theme='secondary' wFull={false} />
        </Flex>
      </Flex>
    </ModalBgLayout>
  );
};

export default CancelApplicationModal;
