import Button from 'common/components/button';
import BgLayout from 'common/components/bg';
import Tracker from 'components/auth/reset-password-success/tracker';
import { useNavigate } from 'react-router-dom';

function View() {
  const navigate = useNavigate();

  return (
    <>
      <BgLayout>
        {/* texts and icon */}
        <div className='flex flex-col text-center items-center gap-6'>

          <h1 className='font-display tracking-tight text-4xl mobile:text-2xl'>
            Password Updated Successfully
          </h1>

          <p className='text-base text-gray-500 font-normal'>
            Your password has been updated successfully. Please proceed to the login page to access your ReLease account.
          </p>
        </div>

        {/* button */}
        <Button type='button' title='Proceed to login' onClick={() => navigate('/login')}/>
      </BgLayout>
      <Tracker />
    </>
  );
}

export default View;
