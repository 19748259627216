/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useGetSinglePlanQuery } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { savePlansValues, setPlanStep } from 'redux/features/plans/plansSlice';
import { isEmpty } from 'common/utils/isEmpty';
import PlanInReview from 'components/plan-apply/plan-in-review';
import PlanStepLeaseDetails from 'components/plan-apply/plan-step-lease-details';
import PlanStepSetPayment from 'components/plan-apply/plan-step-set-payment';
import PlanStepChooseCoverage from 'components/plan-apply/plan-step-choose-coverage';
import PlanStepSelectProperty from 'components/plan-apply/plan-step-select-property';
import PlanStepActivatePlan from 'components/plan-apply/plan-step-activate-plan';
import PlanStepPersonalInfo from 'components/plan-apply/plan-step-personal-info';

const ApplyForPlanLayout = ({ stepNumber, setStepNumber }) => {
  // STATES
  const [status, setStatus] = useState('Pending Payment');

  // HOOKS
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsStatus = searchParams.get('status');
  const paramsPlanId = searchParams.get('planId');
  const dispatch = useDispatch();
  const { data: singlePlan, isLoading: isGettingSinglePlan } =
    useGetSinglePlanQuery(paramsPlanId, {
      skip: isEmpty(paramsPlanId),
    });

  // HANDLERS
  const clearSearchParams = () => {
    searchParams.delete('status');
    searchParams.delete('planId');
    searchParams.delete('step');
    setSearchParams(searchParams, { replace: true });
  };

  const stepComponents = [
    <PlanStepSelectProperty
      stepNumber={stepNumber}
      onNextClick={() => {
        setStepNumber(1);
        dispatch(setPlanStep(1));
        window.scrollTo(0, 0);
      }}
      clearSearchParams={clearSearchParams}
      key={0}
    />,
    <PlanStepPersonalInfo
      stepNumber={stepNumber}
      onBackClick={() => {
        setStepNumber((prevState) => prevState - 1);
        dispatch(setPlanStep(0));
        window.scrollTo(0, 0);
      }}
      onNextClick={() => {
        setStepNumber(2);
        dispatch(setPlanStep(2));
        window.scrollTo(0, 0);
      }}
      clearSearchParams={clearSearchParams}
      key={1}
    />,
    <PlanStepLeaseDetails
      stepNumber={stepNumber}
      onBackClick={() => {
        setStepNumber((prevState) => prevState - 1);
        dispatch(setPlanStep(1));
        window.scrollTo(0, 0);
      }}
      onNextClick={() => {
        setStepNumber(3);
        dispatch(setPlanStep(3));
        window.scrollTo(0, 0);
      }}
      clearSearchParams={clearSearchParams}
      key={2}
    />,
    <PlanStepChooseCoverage
      isGettingPlan={isGettingSinglePlan}
      stepNumber={stepNumber}
      onBackClick={() => {
        setStepNumber((prevState) => prevState - 1);
        dispatch(setPlanStep(2));
        window.scrollTo(0, 0);
      }}
      onNextClick={() => {
        clearSearchParams();
        setStepNumber(4);
        dispatch(setPlanStep(4));
        window.scrollTo(0, 0);
      }}
      key={3}
    />,
    <PlanStepSetPayment
      isGettingPlan={isGettingSinglePlan}
      stepNumber={stepNumber}
      onBackClick={() => {
        setStepNumber((prevState) => prevState - 1);
        dispatch(setPlanStep(3));
        window.scrollTo(0, 0);
      }}
      onNextClick={() => {
        setStepNumber(5);
        dispatch(setPlanStep(5));
        window.scrollTo(0, 0);
      }}
      key={4}
    />,
    <PlanStepActivatePlan
      stepNumber={stepNumber}
      onBackClick={() => {
        setStepNumber((prevState) => prevState - 1);
        dispatch(setPlanStep(4));
        window.scrollTo(0, 0);
      }}
      onNextClick={() => {
        setStepNumber(6);
        dispatch(setPlanStep(6));
        window.scrollTo(0, 0);
      }}
      key={5}
    />,
    <PlanInReview stepNumber={stepNumber} key={6} />,
  ];

  // SIDE EFFECTS
  useEffect(() => {
    setStatus(paramsStatus || status);
  }, []);
  useEffect(() => {
    if (!isEmpty(singlePlan)) {
      dispatch(savePlansValues(singlePlan || {}));
    }
  }, [singlePlan]);

  return (
      <section className='h-full w-full flex flex-col items-center border border-gray-200 divide-y divide-gray-200 rounded-lg mobile:border-none mobile:rounded-none mobile:divide-gray-200' >        
          {
            // Ensure that step number is not greater than the number of steps
            stepNumber > 6 ? stepComponents[6] : stepComponents[stepNumber]
          }
      </section>
  );
};

export default ApplyForPlanLayout;
