import { useEffect, useState } from 'react';
import { IoIosSend } from 'react-icons/io';
import { IoSettingsSharp } from 'react-icons/io5';
import { PiWarningCircleFill } from 'react-icons/pi';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useRetrieveUserDetailsQuery } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { clearClaimsDraft } from 'redux/features/claims/claimsSlice';
import { clearPlansDraft } from 'redux/features/plans/plansSlice';
import { isEmpty } from 'common/utils/isEmpty';
import ActiveLink from 'common/components/dashboard/active-link';
import ConfirmActionModal from 'common/components/confirm-action-modal';
import { logout, setUserDetails } from 'redux/features/auth/authSlice';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import Button from 'common/components/button';
import { FaHome } from 'react-icons/fa';
import DashboardLayout from 'common/layouts/dashboard';

const DashboardWrapperLayout = ({
  children,
  title,
  buttons,
}) => {
  // STATES
  const [confirmModalState, setConfirmModalState] = useState(false);

  // HOOKS
  const { trackLink } = useUserTracker();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isSuccess: isRetrieveUserDetailsSuccess, data: userDetails } =
    useRetrieveUserDetailsQuery();

  // HANDLERS
  const handleLogout = () => {
    dispatch(logout());
    dispatch(clearClaimsDraft());
    dispatch(clearPlansDraft());
    navigate('/login');
  };

  // SIDE EFFECTS
  useEffect(() => {
    if (isRetrieveUserDetailsSuccess && !isEmpty(userDetails)) {
      dispatch(setUserDetails(userDetails));
    }
  }, []);

  // Set up links
  const links = (
    <>
      <ActiveLink
        Icon={FaHome}
        linkId='Home'
        to={'/dashboard/home'}
        text='Home'
      />
      <ActiveLink
        linkId='Plans'
        Icon={IoIosSend}
        to={'/dashboard/plans'}
        text='Plans'
      />
      <ActiveLink
        Icon={PiWarningCircleFill}
        linkId='Claims'
        to={'/dashboard/claims'}
        text='Claims'
      />
      <ActiveLink
        linkId='Settings'
        Icon={IoSettingsSharp}
        to={'/dashboard/settings'}
        text='Settings'
      />
      <Button
        theme='secondary'
        title='Chat with an Expert'
        onClick={() => window.open('mailto:members@releaserent.com')}
      />
    </>
  );

  return (
    <div>
      <DashboardLayout
        links={links}
        user={userDetails}
        onLogoutClick={() => {
          trackLink('Logout');
          setConfirmModalState(true);
        }}
        title={title}
        buttons={buttons}
        children={children}
      />

      {/* MODAL */}
      {confirmModalState && (
        <ConfirmActionModal
          onConfirmClick={handleLogout}
          onCancel={() => setConfirmModalState(false)}
          action='log out'
          description='Are you sure you want to log out of your ReLease account?'
        />
      )}
    </div>
  );
};

export default DashboardWrapperLayout;
