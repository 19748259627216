import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Card,
  Flex,
  Tag,
  Text,
} from '@chakra-ui/react';
import Button from 'common/components/button';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import AnchorLinkTag from 'components/shared/anchor-link-tag';
import { coverageTypes } from 'utils/enums';

const CoverageDetail = ({ title, limit, startDate, endDate, children }) => {
  return (
    <AccordionItem className='w-full'>
      <AccordionButton className='flex text-left items-center justify-between px-6 py-3 gap-4 w-full hover:bg-gray-50 border-gray-200 mobile:justify-center'>
        <Flex className='flex w-full mobile:flex-col'>
          {/* Text */}
          <Flex className='flex-col items-start min-w-[180px]'>
            <h3 className='text-base'>{title}</h3>
          </Flex>

          {/* Limit */}
          {limit && (
            <Flex
              className={`mobile:flex-row mobile:gap-2 text-base text-gray-500`}
            >
              <Text>
                {limit[0]} {limit[1] && `or ${limit[1]}`}
              </Text>
            </Flex>
          )}

          {/* Period */}
          {startDate && endDate && (
            <Flex
              className={`mobile:flex-row mobile:gap-2 text-base text-gray-500`}
            >
              <Text>
                {formatDateToHumanDate(startDate)} -{' '}
                {formatDateToHumanDate(endDate)}
              </Text>
            </Flex>
          )}
        </Flex>

        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel className='border-t px-6 py-4 flex flex-col gap-6 text-sm text-gray-500'>
        {children}
      </AccordionPanel>
    </AccordionItem>
  );
};

const CoverageCard = ({
  coverageType,
  amount,
  children,
  paymentOptions,
  startDate,
  endDate,
}) => {
  // Set up text for coverage details
  const coverageDetails = [
    {
      value: coverageTypes.CANCEL_ANYTIME,
      name: 'Cancel Anytime',
      description:
        'Cancel your upcoming lease for any reason, any time before moving in.',
      detail:
        'Get covered for costs up to 6 months in rent while your apartment is vacant, including outstanding rent, cancellation fees, and lease buyout fees where applicable.',
    },
    {
      value: coverageTypes.LEAVE_ANYTIME,
      name: 'Leave Anytime',
      description:
        'Leave your current lease for any reason, any time during your lease.',
      detail:
        'Get covered for costs up to 6 months in rent while your apartment is vacant, including outstanding rent, cancellation fees, and lease buyout fees where applicable.',
    },
  ];

  return (
    <Flex className='rounded-lg border border-gray-200 flex flex-col'>
      <Flex className={`flex-col gap-6 w-full p-6`}>
        {/* Header */}
        <Flex className='w-full flex-col gap-6 sm:flex-row sm:justify-between'>
          <Flex
            className={`w-full flex-col gap-1 pr-6 ${amount && 'sm:max-w-lg'} ${
              coverageType === coverageTypes.LEAVE_ANYTIME && 'mb-10'
            } sm:mb-0`}
          >
            <Text className='text-lg font-medium'>
              {
                coverageDetails.find(
                  (coverage) => coverage.value === coverageType
                ).name
              }
            </Text>
            <Text className='text-base text-gray-500'>
              {
                coverageDetails.find(
                  (coverage) => coverage.value === coverageType
                ).description
              }
            </Text>
          </Flex>

          {/* Show terms and conditions prominently if no amount is set (i.e. post-purchase) */}
          {!amount && (
            <Flex className='flex-col gap-4'>
              <Button
                theme='secondary'
                title='View Terms & Conditions'
                onClick={() => {
                  window.open(
                    coverageType === coverageTypes.CANCEL_ANYTIME
                      ? 'https://www.releaserent.com/legal/release-cancel-anytime-terms-conditions'
                      : coverageType === coverageTypes.LEAVE_ANYTIME
                      ? 'https://www.releaserent.com/legal/release-leave-anytime-terms-conditions'
                      : 'https://www.releaserent.com/legal/terms-of-service'
                  );
                }}
              />
            </Flex>
          )}

          {/* Price section */}
          {amount && (
            <Flex className={`flex-col min-w-fit relative mt-auto sm:mb-2`}>
              {coverageType === coverageTypes.LEAVE_ANYTIME && (
                <Text className='absolute -top-5 text-gray-500 text-sm'>
                  Starting From
                </Text>
              )}
              <Flex className='items-end gap-2 align-baseline'>
                <Text className='text-3xl font-medium'>
                  ${amount?.toFixed(2)}
                </Text>
                <Text className='text-base text-gray-500 pb-1'>
                  {coverageType === coverageTypes.LEAVE_ANYTIME
                    ? 'per month'
                    : 'total'}
                </Text>
              </Flex>
            </Flex>
          )}
        </Flex>

        {/* Show terms and conditions less prominently if amount is set (i.e. pre-purchase) */}
        {amount && (
          <Flex className='flex-col gap-1'>
            <Text className='text-sm text-gray-500'>
              {
                coverageDetails.find(
                  (coverage) => coverage.value === coverageType
                ).detail
              }{' '}
              <AnchorLinkTag
                hrefPath={
                  coverageType === coverageTypes.CANCEL_ANYTIME
                    ? 'https://www.releaserent.com/legal/release-cancel-anytime-terms-conditions'
                    : coverageType === coverageTypes.LEAVE_ANYTIME
                    ? 'https://www.releaserent.com/legal/release-leave-anytime-terms-conditions'
                    : 'https://www.releaserent.com/legal/terms-of-service'
                }
              >
                View Coverage Terms & Conditions
              </AnchorLinkTag>
            </Text>
          </Flex>
        )}
      </Flex>

      {/* Coverage Details */}
      <Accordion
        allowMultiple
        className='flex w-full flex-col overflow-hidden divide-y divide-gray-200'
      >
        {startDate && endDate ? (
          <CoverageDetail
            title='Coverage Period'
            startDate={startDate}
            endDate={endDate}
          >
            Your coverage period is the time frame in which you are covered for
            cancellations. For example, if your coverage period is between Sep
            1, 2024 and Mar 1, 2025, you are eligible to cancel your lease and
            receive benefits during this time frame.
          </CoverageDetail>
        ) : null}
        {children}
      </Accordion>

      {/* Payment Options */}
      {paymentOptions && (
        <Flex className='flex-col gap-4 px-6 py-8 border-t border-gray-200'>{paymentOptions}</Flex>
      )}
    </Flex>
  );
};

export { CoverageCard, CoverageDetail };
