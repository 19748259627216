import { HiOutlineUser } from 'react-icons/hi';
import { MdOutlineMailOutline } from 'react-icons/md';
import Input from 'common/components/input';
import AnchorLinkTag from 'components/shared/anchor-link-tag';
import { Formik } from 'formik';
import {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import { enforcePhoneNumberPattern } from 'utils/enforcePhoneNumberPatter';
import { extractInitials } from 'common/utils/extractInitials';
import * as Yup from 'yup';
import WrapInputLabel from 'common/components/wrap-input-label';
import FormError from 'common/components/form-error';
import Button from 'common/components/button';
import { PhoneNumberInput } from 'common/components/phone-input';
import { ApplyFormField, ApplyFormLayout } from 'common/layouts/apply-form';

// Phone number is not hooked up to anything

const ContactForm = ({ user }) => {
  const firstName = user?.firstName
    ? user?.firstName
    : user?.name.split(' ')[0];
  const lastName = user?.lastName ? user?.lastName : user?.name.split(' ')[1];
  const email = user?.email || 'hello@releaserent.com';
  const phone = user?.phone;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        phone: formatPhoneNumberIntl(enforcePhoneNumberPattern(phone)) || '',
      }}
      validationSchema={Yup.object({
        phone: Yup.string()
          .required('Phone number is required')
          .test('is-valid-phone', 'Phone number is invalid', (value) => {
            return isValidPhoneNumber(value || '');
          }),
      })}
      onSubmit={() => {}}
    >
      {({
        isValid,
        handleBlur,
        handleSubmit,
        values,
        setFieldValue,
        dirty,
      }) => {
        return (
          <form
            onSubmit={(e) => e.preventDefault()}
            className='w-full flex flex-col gap-6'
          >
            {/* note */}
            <div className='flex flex-col gap-1'>
              <h2 className='text-lg font-medium'>
                Contact Information
              </h2>
              <p className='text-base text-gray-500'>
                To update your contact information, please{' '}
                <AnchorLinkTag
                  hrefPath='mailto:members@releaserent.com'
                >
                  contact us
                </AnchorLinkTag>
                .
              </p>
            </div>

            {/* form control container */}
            <ApplyFormLayout className='pt-0 pb-0'>
              <ApplyFormField small>
                <WrapInputLabel isRequired>First Name</WrapInputLabel>
                <Input
                  disabled={true}
                  value={firstName}
                  Icon={HiOutlineUser}
                  placeholderText='First Name'
                />
              </ApplyFormField>
              <ApplyFormField small>
              <WrapInputLabel isRequired>Last Name</WrapInputLabel>
                <Input
                  disabled={true}
                  value={lastName}
                  Icon={HiOutlineUser}
                  placeholderText='Last Name'
                />
              </ApplyFormField>
              <ApplyFormField>
                <WrapInputLabel isRequired>Email Address</WrapInputLabel>
                <Input
                  disabled={true}
                  value={email}
                  Icon={MdOutlineMailOutline}
                  placeholderText='Email address'
                />
              </ApplyFormField>
              <ApplyFormField>
                <WrapInputLabel isRequired>Phone Number</WrapInputLabel>
                <PhoneNumberInput
                  disabled={true}
                  value={values.phone}
                  onChange={(e) => {
                    setFieldValue('phone', e);
                  }}
                  onBlur={handleBlur}
                />
                <FormError name='phone' />
              </ApplyFormField>
            </ApplyFormLayout>

            {/* button */}
            {/* <Button
              onClick={handleSubmit}
              disabled={!isValid || !dirty}
              title='Save changes'
            /> */}
          </form>
        );
      }}
    </Formik>
  );
};

export default ContactForm;
