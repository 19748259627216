const AuthLabel = ({ title = 'Add title', isActive = true }) => {
  // DATA INTIALIZATION
  const bgColor = isActive ? 'bg-black' : 'bg-white';
  const textColor = isActive ? 'text-white' : 'text-black';
  const hover = isActive ? 'hover:opacity-70' : 'hover:bg-gray-100';
  return (
    <span
      className={`${bgColor} ${textColor} h-9 py-4 rounded-full px-6 text-md inline-flex items-center font-semibold ${hover} cursor-pointer transition-all duration-200`}
    >
      {title}
    </span>
  );
};

export default AuthLabel;
