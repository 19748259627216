import { Icon } from '@chakra-ui/react';
import Button from 'common/components/button';
import LoadingSpinner from 'components/shared/loading-spinner';

const SetupCard = ({
  title,
  subtitle,
  message,
  buttonTitle,
  onClick,
  className,
  icon,
}) => {
  return (
    <div className={`flex flex-col gap-3 ${className}`}>
      {/* Show an Icon or Loading Spinner */}
      {/* {icon 
        ? <Icon as={icon} className='h-10 text-5xl mb-4 opacity-25'/>
        : <LoadingSpinner className='h-10 mb-4 flex' />
      } */}

      <h2 className='text-lg font-medium'>{title}</h2>
      <p className='text-base'>{subtitle}</p>
      <p className='text-base text-gray-500 mb-4'>{message}</p>

      {/* Show button if defined */}
      <div>
        {buttonTitle && onClick ? (
          <Button onClick={onClick} title={buttonTitle} wFull={false} />
        ) : null}
      </div>
    </div>
  );
};

export { SetupCard };
