/* eslint-disable no-unused-vars */
import { Flex, Text } from '@chakra-ui/react';
import FinishSetupIntent from 'components/stripe/finish-setup-intent';
import Payment from 'components/stripe/payment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  useGetSinglePlanQuery,
  useRequestSetupIntentMutation,
  useRetrieveUserDetailsQuery,
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import {
  selectPlansValues,
  selectPaymentMode,
  selectCoverageSelection,
} from 'redux/features/plans/plansSlice';
import { planPaymentMode, coverageTypes } from 'utils/enums';
import { isEmpty } from 'common/utils/isEmpty';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import PlanInfoCard from 'components/plans/plan-info-card';
import AnchorLinkTag from 'components/shared/anchor-link-tag';
import ContactCard from 'components/shared/contact-card';
import PaymentSummaryCard from 'components/plans/payment-summary-card';
import ApplyButtons from 'layouts/apply-buttons';
import ApplyTitle from 'components/apply-shared/apply-title';
import ApplyContent from 'components/apply-shared/apply-content';

const PlanStepSetPayment = ({
  onBackClick,
  onNextClick,
  isGettingPlan,
  stepNumber,
}) => {
  const { trackPageLoad, trackCustomEvent } = useUserTracker();

  useEffect(() => {
    trackPageLoad('PlanStepSetPayment');
  }, []);

  // STATES
  const [clientSecret, setSecret] = useState();
  const [isPaymentSuccess, setPaymentSuccess] = useState(false);

  // HOOKS
  const plansValue = useSelector(selectPlansValues);
  const { data: singlePlan, isLoading: isGettingSinglePlan } =
    useGetSinglePlanQuery(plansValue?.id, {
      skip: isEmpty(plansValue?.id),
    });

  const [requestSetupIntent] = useRequestSetupIntentMutation();
  const [searchParams, setSearchParams] = useSearchParams();
  const setupIntent = searchParams.get('setup_intent');
  const { isSuccess: isRetrieveUserDetailsSuccess, data: userDetails } =
    useRetrieveUserDetailsQuery();

  const address = singlePlan?.lease?.address || {};
  const paymentMode = useSelector(selectPaymentMode);
  const coverageSelection = useSelector(selectCoverageSelection);

  console.log('singlePlan', singlePlan);

  // SIDE EFFECTS
  useEffect(() => {
    requestSetupIntent()
      .unwrap()
      .then((response) => {
        setSecret(response?.data?.clientSecret);
      })
      .catch((error) => {
        toast.error(error?.message || 'Failed to setup intent!');
      });
  }, []);

  return (
    <div className='flex flex-col w-full h-full'>
      <ApplyTitle
        title='Set Payment'
        subtitle='Choose your payment schedule and add a payment method.'
        buttons={
          <ApplyButtons
            loading={isGettingPlan}
            handleSubmit={onNextClick}
            onBackClick={onBackClick}
            onNextClick={onNextClick}
            disableBack={!isEmpty(setupIntent)}
            disableNext={!isPaymentSuccess}
          />
        }
      />

      {/* main content */}
      <ApplyContent>
        {/* Heading */}
        <Flex className='flex-col gap-2 w-full pt-4'>
          <h2 className='text-lg font-medium'>Complete Payment</h2>
          <Text className='text-base text-gray-500'>
            Please complete payment by credit card or bank account in order to
            complete your application. Once completed, your application will be
            reviewed. Once approved, you will be able to activate your plan. If
            your application is not approved, you will receive a refund for any
            payments made.
          </Text>
          <Text className='text-base text-gray-500'>
            Your coverage may be voided if you misrepresent or conceal any
            material information.{' '}
            <AnchorLinkTag hrefPath='https://www.releaserent.com/legal/lease-flexibility-coverage-terms-conditions'>
              Read the full terms and conditions.
            </AnchorLinkTag>
          </Text>
        </Flex>

        {/* plan info card */}
        <Flex className='flex-col gap-6 w-full'>
          <Flex className='flex-col gap-1 w-full items-left'>
            <h2 className='text-lg font-medium'>
              Review Your Plan and Coverages
            </h2>
            <Text className='text-base text-gray-500'>
              Please review your plan and the coverages you have selected below
              before completing payment.
            </Text>
          </Flex>

          <PlanInfoCard
            plan={singlePlan}
            member={userDetails}
            selectedCancelAnytime={
              coverageSelection[coverageTypes.CANCEL_ANYTIME]
            }
            selectedLeaveAnytime={
              coverageSelection[coverageTypes.LEAVE_ANYTIME]
            }
          />
        </Flex>

        <PaymentSummaryCard
          plan={singlePlan}
          selectedCancelAnytime={
            coverageSelection[coverageTypes.CANCEL_ANYTIME]
          }
          selectedLeaveAnytimeOnetime={
            coverageSelection[coverageTypes.LEAVE_ANYTIME] ===
            planPaymentMode.ONE_TIME
          }
          selectedLeaveAnytimeSubscription={
            coverageSelection[coverageTypes.LEAVE_ANYTIME] ===
            planPaymentMode.SUBSCRIPTION
          }
          complete={false}
          paymentMode={paymentMode}
          card={false}
        />

        {/* Payment component */}
        <div>
          {clientSecret &&
            (!isEmpty(setupIntent) ? (
              <FinishSetupIntent
                removeSetupIntent={() => {
                  searchParams.delete('setup_intent');
                  setSearchParams(searchParams, { replace: true });
                }}
                onNextClick={onNextClick}
                setPaymentSuccess={setPaymentSuccess}
                setupIntentID={setupIntent}
              />
            ) : (
              <Payment
                clientSecret={clientSecret}
                returnUrl={`${window.location.href}`}
              />
            ))}
        </div>

        <ContactCard />
      </ApplyContent>
    </div>
  );
};

export default PlanStepSetPayment;
