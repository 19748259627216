import LogoImage from 'common/components/logo-image';
import WrapInputLabel from 'common/components/wrap-input-label';
import { Form, Formik } from 'formik';
import { useState, useEffect } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import Button from 'common/components/button';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Tracker from 'components/auth/signup/tracker';
import FormError from 'common/components/form-error';
import PasswordInput from 'common/components/password-input';
import AuthMainLayout from 'layouts/auth-main';
import {
  useConfirmAccountMutation,
  useRegisterMutation,
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import { setCredentials, logout } from 'redux/features/auth/authSlice';
import {
  LOWERCASECASE_REGEX,
  NOT_STRICT_DIGIT_REGEX,
  UPPERCASE_REGEX,
} from 'utils/regex';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import { clearClaimsDraft } from 'redux/features/claims/claimsSlice';
import { clearPlansDraft } from 'redux/features/plans/plansSlice';
import { PhoneNumberInput } from 'common/components/phone-input';
import { ApplyFormField } from 'common/layouts/apply-form';
import { PasswordRequirements } from 'common/components/password-requirements';

// TODO remove the confirm-account logic in sign up

const ConfirmAccount = () => {
  console.log('Confirm account');
  // STATES
  const [hasSignupCode, setHasSignupCode] = useState(false);

  // HOOKS
  const [searchParams] = useSearchParams();
  const userToken = searchParams.get('token');
  const firstName = searchParams.get('firstName');
  const lastName = searchParams.get('lastName');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [register, { isLoading: isRegisterLoading }] = useRegisterMutation();
  const [confirmAccount, { isLoading: isConfirmingAccount }] =
    useConfirmAccountMutation();
  const {
    trackIdentity,
    trackFieldPopulate,
    trackFormSubmission,
    trackFormSubmissionSuccess,
    trackFormSubmissionError,
  } = useUserTracker();

  useEffect(() => {
    dispatch(logout());
    dispatch(clearClaimsDraft());
    dispatch(clearPlansDraft());
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        phone: '',
        password: '',
        repeatPassword: '',
      }}
      onSubmit={({ phone, password, repeatPassword }) => {
        confirmAccount({
          token: userToken,
          password: password,
          phone: phone,
        })
          .unwrap()
          .then(({ data }) => {
            toast.info(data?.message || 'Success');
            console.log('confirm account data: ', data);
            dispatch(
              setCredentials({
                isLoggedIn: true,
                token: data?.token,
                user: data?.user,
              })
            );
            navigate('/account-created-success');
          })
          .catch((error) => {
            toast.error(error?.data?.msg || 'Confirm Account Failed');
          });
      }}
      validationSchema={Yup.object({
        phone: Yup.string()
          .required('Phone number is required')
          .test('is-valid-phone', 'Phone number is invalid', (value) => {
            return isValidPhoneNumber(value || '');
          }),
        password: Yup.string()
          .required('Required')
          .min(8, 'Must be at least 8 characters long')
          .matches(NOT_STRICT_DIGIT_REGEX, 'Must contain a number')
          .matches(LOWERCASECASE_REGEX, 'Must contain a lowercase')
          .matches(UPPERCASE_REGEX, 'Must contain an uppercase'),
        repeatPassword: Yup.string()
          .required('Required')
          .oneOf([Yup.ref('password')], 'Passwords must match'),
      })}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        isValid,
        setFieldTouched,
        setFieldValue,
      }) => {
        return (
          <AuthMainLayout>
            <div className='px-6 md:px-8 flex flex-col gap-12'>
              {/* Welcome header */}
              <div className='flex flex-col'>
                <div className='text-2xl mobile:text-xl font-display tracking-tight text-gray-500'>Welcome</div>
                {/* caption */}
                <div className='text-4xl font-dislay tracking-tight'>
                  {firstName} {lastName}
                </div>
                <div className='text-base text-gray-500 pt-12'>
                  Activate your new ReLease account with your phone number and a password to sign in with.
                </div>
              </div>

              {/* Form */}
              <Form className='flex flex-col items-stretch w-full gap-9'>
                {/* fields container */}
                <div className='w-full flex flex-col gap-6'>
                  {/* phone number */}
                  <ApplyFormField>
                    <WrapInputLabel isRequired>                      
                        Phone Number:
                      </WrapInputLabel>
                    <PhoneNumberInput
                      name='phone'
                      value={values.phone}
                      onChange={(e) => {
                        setFieldValue('phone', e);
                      }}
                      onBlur={(e) => {
                        handleBlur(e);
                        trackFieldPopulate('phone');
                      }}
                    />
                    <FormError name='phone' />
                  </ApplyFormField>

                  {/* password */}
                  <ApplyFormField>
                    <WrapInputLabel isRequired>
                        New Password:
                      </WrapInputLabel>
                    <PasswordInput
                      name='password'
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldTouched('password');
                        handleChange(e);
                      }}
                      value={values.password}
                      placeholderText='New password'
                    />
                    <FormError name='password' />

                    <PasswordRequirements password={values.password} />
                  </ApplyFormField>

                  {/*Re-enter password */}
                  <ApplyFormField>
                    <WrapInputLabel isRequired>
                        Confirm Password:
                      </WrapInputLabel>
                    <PasswordInput
                      name='repeatPassword'
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldTouched('repeatPassword');
                        handleChange(e);
                      }}
                      value={values.repeatPassword}
                      placeholderText='Re-enter new password'
                    />
                    <FormError name='repeatPassword' />
                  </ApplyFormField>
                </div>

                {/* button */}
                <Button
                  disabled={
                    !isValid || isRegisterLoading || isConfirmingAccount
                  }
                  loading={isRegisterLoading || isConfirmingAccount}
                  type='submit'
                  title='Activate Account'
                />
                <Tracker
                  values={values}
                  errors={errors}
                  touched={touched}
                  formName='confirmAccount'
                />
              </Form>
            </div>
          </AuthMainLayout>
        );
      }}
    </Formik>
  );
};

export default ConfirmAccount;
