import LogoImage from 'common/components/logo-image';

const AuthAsideLayout = () => {
  return (
    <aside className='h-full justify-center relative hidden md:flex'>
      {/* mesh bg */}
      <img
        src='/images/login/side-bg.png'
        alt='aside mesh bg'
        className='absolute top-0 left-0 w-full h-full z-0 object-cover'
      />

      {/* wrapper */}
      <div className='flex flex-col gap-16 relative px-8 py-16 max-w-md'>
        <LogoImage dark={true} size='s' />

        {/* texts */}
        <div className='flex flex-col gap-7'>
          <p className='font-display tracking-tight text-4xl'>
            Flexibility to move out any time, penalty-free
          </p>

          <p className='text-lg font-normal text-gray-500'>
            When you're covered by ReLease you can rent worry-free, with the flexibility to move out any time, penalty-free
          </p>
        </div>
      </div>
    </aside>
  );
};

export default AuthAsideLayout;
