import Button from 'common/components/button';
import BgLayout from 'common/components/bg';
import { useNavigate } from 'react-router-dom';
import { useGetAllPlansQuery } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { isEmpty } from 'common/utils/isEmpty';

const ConfirmAccountSuccess = () => {
  // HOOKS
  const navigate = useNavigate();
  const { data: userPlans, isLoading: isFetchingPlans } = useGetAllPlansQuery();

  return (
    <BgLayout>
        {/* texts */}
        <div className='flex flex-col text-center gap-6'>
          <h1 className='font-display tracking-tight text-4xl mobile:text-2xl'>
            Account Activated
          </h1>

          <p className='text-gray-500 text-base font-normal'>
            You have successfully activated your ReLease account.
          </p>
        </div>

        {/* button */}
        <Button
          onClick={() => {
            let planUrl = `/dashboard/plans/apply`;
            let plan;

            if (!isEmpty(userPlans)) {
              plan = userPlans[0];
            }

            if (plan && plan.id && plan.status) {
              planUrl = `${planUrl}?step=${
                plan.status === 'created' ? 1 : 2
              }&planId=${plan?.id}&isEdit=true`;
            }
            navigate(planUrl);
          }}
          disabled={isFetchingPlans}
          title={
            isFetchingPlans
              ? 'Loading...'
              : isEmpty(userPlans)
                ? 'Apply for a New ReLease Plan'
                : 'Activate Your ReLease Plan'
          }
        />
      </BgLayout>
  );
};

export default ConfirmAccountSuccess;
